import React from 'react';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Myprofile from './pages/Myprofile';
import Changepassword from './pages/Changepassword';
import Updateprofilepic from './pages/Updateprofilepic';
import Mypaymenthistory from './pages/Mypaymenthistory';
import Startliveexam from './pages/Startliveexam';
import Liveexam from './pages/Liveexam';
import Liveexamresult from './pages/Liveexamresult';
import LiveScoreView from './pages/LiveScoreView';
import Liveexamreview from './pages/Liveexamreview';
import Monthwisescore from './pages/Monthwisescore';

import Startpracticeexam from './pages/Startpracticeexam';
import Practiceexam from './pages/Practiceexam';
import Practiceexamresult from './pages/Practiceexamresult';

import ForgotPassword from './pages/ForgotPassword';
import Updatepasswordcontent from './content/Updatepasswordcontent';

import Thankyou from './pages/Thankyou';

import Main from './pages/Main';

import Demo from './pages/Demo';

import "./App.css";


// import {BrowserRouter,Routes,Route} from 'react-router-dom';
import {Routes, Route} from 'react-router-dom';
import Packages from './pages/Packages';
import ExamPracticeSetList from './pages/ExamPracticeSetList';
import StartPracticeSet from './pages/StartPracticeSet';
import PracticeSet from './pages/PracticeSet';
import PracticeSetShuffle from './pages/PracticeSetShuffle';
import Packageplan from './pages/Packageplan';
import Mockpracticesetlist from './pages/Mockpracticesetlist';
import Successstory from './pages/Successstory';
import Register from './pages/Register';



function App() {
  return (
    <> 

        <Routes>
          <Route exact path='/' element={<Login />} />
          <Route exact path='/dashboard' element={<Dashboard />} />
          <Route exact path='/my-profile' element={<Myprofile />} />
          <Route exact path='/change-password' element={<Changepassword />} />
          <Route exact path='/update-profile-pic' element={<Updateprofilepic />} />
          <Route exact path='/my-payment-history' element={<Mypaymenthistory />} />

          <Route exact path='/startliveexam' element={<Startliveexam />} />
          <Route exact path='/liveexam/:qsl' element={<Liveexam />} />
          <Route exact path='/liveexamresult' element={<Liveexamresult />} />
          <Route exact path="/livescoreview/:suid/:livexmid" element={<LiveScoreView/>}/>
          <Route exact path='/liveexamreview/:examdate/:examid' element={<Liveexamreview />} />
          <Route exact path='/monthwisescore' element={<Monthwisescore />} />

          <Route exact path='/startpracticeexam/:practicesetid' element={<Startpracticeexam />} />
          <Route exact path='/practiceexam/:qsl' element={<Practiceexam />} />
          <Route exact path='/practiceexamresult' element={<Practiceexamresult />} />

          <Route exact path='/forgot-password' element={<ForgotPassword />} />
          <Route exact path='/update-password' element={<Updatepasswordcontent />} />

          <Route exact path='/main' element={<Main />} />

          <Route exact path='/demo' element={<Demo />} />

          <Route exact path='/thankyou' element={<Thankyou />} />

          <Route exact path='/packages/:packageid' element={<Packages />} />
          <Route exact path='/exampracticesetlist/:subpackageid/:packageid' element={<ExamPracticeSetList />} />
          <Route exact path='/startpracticeset' element={<StartPracticeSet />} />
          <Route exact path='/practiceset/:qsl' element={<PracticeSet />} />
          <Route exact path='/practicesetshuffle/:qsl' element={<PracticeSetShuffle />} />

          <Route exact path='/packageplan' element={<Packageplan />} />

          <Route exact path='/mockpracticesetlist' element={<Mockpracticesetlist />} />

          <Route exact path='/successstory' element={<Successstory />} />
          <Route exact path='/register' element={<Register />} />

        </Routes>
        {/* </Router> */}
      {/* </BrowserRouter> */}

   

    </>
  );
}

export default App;
