import {Link} from 'react-router-dom';

import react, { useRef, useEffect, useState } from "react";
import axios from 'axios';

import dateFormat from 'dateformat';

// For Dashboard Loader
import DashboardLoader from '../include/DashboardLoader';
// For Dashboard Loader
// For Noticebaord Loader
import NoticeboardLoader from '../include/NoticeboardLoader';
// For Noticebaord Loader

function Monthwisescorecontent(props) {

    const txtblue={
      color: '#1500b2',
      fontWeight: '700',
      fontSize: 'medium'
    };

    const txtwhite={
      color: '#000',
      fontWeight: '700',
      fontSize: 'medium'
    };

    let [isbtn,setIsbtn]=useState(true);
    //localStorage.setItem("studid",1000);

    // if (localStorage.getItem("studtoken")==null) {
    //   window.location.href="/";
    // }

    let [studname,setStudname]=useState('');
    let [enrollid,setEnrollid]=useState('');
    let [studstatus,setStudstatus]=useState('');
    let [batchname,setBatchname]=useState('');


    //today date
    const now = new Date();
    const curdate=dateFormat(now, "yyyy-mm-dd");


    //LOADER
    let [loader,setLoader]=useState(false);
    //LOADER

    let [pic,setPic]=useState(null);

    async function getToken() {

      setLoader(true);

      var tokenval=localStorage.getItem("tokenID");
      if (tokenval!=null) {
        var resp=await fetch("https://safalata.net/safalatalara/api/gettoken", {
          headers: {
            "Content-Type":"application/json",
            "Authorization":"Bearer "+tokenval
          }
        });
        var data=await resp.json();
        setStudname(data.name);
        setEnrollid(data.enroll_id);
        setStudstatus(data.status);
        setPic(data.pic);

        if (data.name.length>1 && localStorage.getItem("enrollid2")==null) {
          localStorage.setItem("enrollid2",data.enroll_id);
        }

        var fd=new FormData();
        fd.append("batchid",data.batch_slno);
        var resp2=await axios.post("https://safalata.net/safalatalara/api/getbatchname",fd);
        var data2=resp2.data;
        setBatchname(data2);

        setLoader(false);

      } else {
        window.location.href="/";
      }

    }

    // const listcount=0;

    //LIVE EXAM SCORE LIST
    let [livescorearr,setLivescorearr]=useState([]);
    let [monthyeararr,setMonthyeararr]=useState([]);
    let [practicescorearr,setPracticescorearr]=useState([]);
    let [yearmonth,setYearmonth]=useState("");


    function getNostorage() {
      if (localStorage.getItem("livesetid")!=null) {
        localStorage.removeItem("livesetid");
      }
    }


    async function getstudscoremonthyear() {
        // alert(localStorage.getItem("enrollid2"));
        var fd=new FormData();
        fd.append("enrollid",localStorage.getItem("enrollid"));
        var resp=await axios.post("https://safalata.net/safalatalara/api/getstudscoremonthyear_practice",fd);
        var data=resp.data;
        setMonthyeararr(data);
        // console.log()
    }

    let [allnoticeboardlist, setAllnoticeboardlist]=useState([]);

    async function noticeboarddetails() {
        var resp=await axios.get("https://safalata.net/safalatalara/api/getnoticeboard_mobapp");
        var data=resp.data;
        setAllnoticeboardlist(data.noticeboard);
    }

    
    // let [monthyeararr,setMonthyeararr]=useState([]);

    useEffect(()=>{
      getToken();
      getstudscoremonthyear();
      getNostorage();

      // getnoticeboard();

      noticeboarddetails();

    },[])

    return(
        <>        
        
        {/* {listcount} */}

        <div id="content">
            <div className="p-4 contentWrapper">
            <div className="row mb-1">

<div className="col-sm-7">
<h4 className="mb-3 font-weight-bold text-success">Dasboard</h4>
{!loader?
    <p className="dashboardtxt">
      {/* {localStorage.getItem("pic").length>1?
      <img src={"https://safalata.net/safalatalara/uploadpic/"+localStorage.getItem("pic")} alt="" className="profile_img rounded border border-success" style={{float: 'left', width: '100px', marginRight: 10, borderRadius: '5px'}} />:''} */}
      <span style={{fontSize: 'large', color: '#870017', textTransform: 'uppercase'}}>{studname}</span><br/>
      {/* Batch Name : <span style={txtblue}>{batchname}</span><br/> */}
      Enrollment ID : <span style={txtblue}>{enrollid}</span>
      {/* Student Status : {studstatus==1?<span className="text text-success">ACTIVE</span>:<span className="text text-danger">INACTIVE</span>} */}
    </p>                        
    // <p className="dashboardtxt">{studname}</p>
    // <p className="dashboardtxt">Batch Name : {batchname}</p>
    // <p className="dashboardtxt">Enrollment ID : {enrollid}</p>
    // <p className="dashboardtxt">Student Status : {studstatus==1?'ACTIVE':'INACTIVE'}</p>

:<DashboardLoader/>}
</div>



{/* <div className="col-sm-3">

</div> */}
<div className="col-sm-5">
{!loader?
<h6 className="card-title text-defult font-weight-bold">
<span style={{fontWeight:700, fontSize: 'large'}}>Notice Board</span><br/>
{allnoticeboardlist.map((e)=>

<div style={{fontWeight:600, fontSize: 'medium'}} className="text text-danger">
Notice : {e.noticeboard_details}. ( Date : {dateFormat(e.noticeboard_date_time, "mmmm dd, yyyy HH:MM")} )
</div>
)}

{/* <span style={{fontWeight:700, fontSize: 'medium'}}>Date : {dateFormat(noticedatetime, "mmmm dd, yyyy HH:MM")}</span> */}
</h6>
// <p>Notice : # Next new batch (Batch-13) is commenced from 8th January 2022 at 12.30 p.m. # We commence new batch in two different sessions - January (1st week) and July (1st Week). # This is 12 months course.
//  Date : 20-08-2021</p>
:<NoticeboardLoader/>}





</div>


</div>

                <div className="row mb-4">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10 bg_white" style={{margin: 10, padding: 20}}>
                        <h6 className="card-title text-defult font-weight-bold" style={{fontSize: 'large'}}>Select Month</h6>
                        <div className="bg_white">
                        <select className='form-control' onChange={async(ev)=>{
                            if (ev.target.value.length>0) {
                              setPracticescorearr([]);
                              var fd=new FormData();
                              fd.append("enrollid",localStorage.getItem("enrollid"));
                              fd.append("yearmonth",ev.target.value);
                              var resp=await axios.post("https://safalata.net/safalatalara/api/practicescorelistbyyearmonth",fd);
                              var data=resp.data;
                              console.log(data.practicescore)
                              setPracticescorearr(data.practicescore);
                              setYearmonth(ev.target.value);
                            } else {
                              setPracticescorearr([]);
                              setYearmonth("");
                            }
                            
                        }} style={{fontSize: 'large'}}>
                        <option value="">-----------SELECT MONTH WITH YEAR----------</option>
                        {/* {monthyeararr.map((n)=> */}
                        {monthyeararr.map((e)=>
                        <option value={e.studpracticeexamscore_year_month}>{e.studpracticeexamscore_year_month}</option>
                        )}
                        </select>
                        </div>
                    </div>
                    <div className="col-sm-1"></div>
                </div>

                <div className="row mb-4">
                     <div className="col-sm-12">
                         <h6 className="card-title text-defult font-weight-bold" style={{fontSize: 'large'}}>Month Wise Score List<br/>{yearmonth.length>0? '['+yearmonth+']' : ''}</h6>
                         <div className="bg_white border rounded">            
                            <table className="table">
                                <thead className="thead-dark">
                                  <tr>
                                    <th>Live Exam</th>
                                    <th>Total Mark</th>
                                    <th>Correct</th>
                                    <th>Wrong</th>
                                    <th>Skip</th>
                                    <th>Exam Date</th>
                                    <th className='text-center'>Scored</th>
                                    {/* <th className="text-center">Review</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                      
                                {!loader?
                                    practicescorearr.map((e)=>
                                    <tr key={e.stud_practice_set_id}>

                                    <td>{e.practiceset_name}</td>
                                    <td>{e.total_mark}%</td>
                                    <td>{e.studpracticeexamscore_correct}</td>
                                    <td>{e.studpracticeexamscore_wrong}</td>
                                    <td>{e.studpracticeexamscore_skip}</td>
                                    <td>{dateFormat(e.studpracticeexamscore_date, "dd/mm/yyyy")}</td>
                                    <th className='text-center'>{e.stud_practiceset_score}</th>
                                    {/* <td className="text-center">
                                      <Link to={"/liveexamreview/"+dateFormat(e.studliveexamscore_date, "yyyy-mm-dd")+"/"+e.stud_live_set_id} className="btn btn-success">Review</Link>
                                    </td> */}
                                    
                                    </tr>
                                    
                                    )
                                  :
                                  ''
                                  }


                                </tbody>
                              </table>
                        </div>
                    </div>
                </div>
           

            </div>  
        </div>
       
        </>
    );
}

export default Monthwisescorecontent;