import react from "react";
import Examheader from "../include/Examheader";
import PracticeSetContent from "./../content/PracticeSetContent";

function PracticeSet() {
    return(
        <>
        <Examheader />
        <div className="wrapper"> 
            <PracticeSetContent />        
        </div>
        </>
    );
}

export default PracticeSet;