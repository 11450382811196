import {Link} from 'react-router-dom';

import react, { useRef, useEffect, useState } from "react";
import axios from 'axios';

import {useNavigate} from "react-router-dom";

function Thankyoucontent() {

   let navigate = useNavigate();

   if (localStorage.getItem("tokenID")==null) {
      // window.location.href="/";
         let path = `/`; 
         navigate(path);
   }

   function getNostorage() {
      if (localStorage.getItem("livesetid")!=null) {
        localStorage.removeItem("livesetid");
      }
   }

   let [msg,setMsg]=useState("");
   let [msgcode,setMsgcode]=useState("");
   let [msgcolor,setMsgcolor]=useState("");

   let [pic,setPic]=useState(null);
   let [newpic,setNewpic]=useState(null);

   let [isbtn,setIsbtn]=useState(true);
   
   
   async function getToken() {
      var tokenval=localStorage.getItem("tokenID");
      if (tokenval!=null) {
      
      var resp=await fetch("https://safalata.net/safalatalara/api/gettoken", {
         headers: {
            "Content-Type":"application/json",
            "Authorization":"Bearer "+tokenval
         }
      });
      var data2=await resp.json();
      localStorage.setItem("enrollid2", data2.enroll_id);
      localStorage.setItem("name", data2.name);
      localStorage.setItem("status", data2.status);
      localStorage.setItem("batchid",data2.batch_slno);
      localStorage.setItem("pic",data2.pic);
      localStorage.setItem("usertoken",data2.usertoken);
      setIsbtn(true);
         // let path = `/dashboard`; 
         // navigate(path);
     
      }
   }
   
   
   

   useEffect(()=>{
      getToken();

      getNostorage();
   },[])

return(
<>
<div id="content">
   <div className="p-4 contentWrapper">
      <div className="row">
         <div className="col-lg-7 dasboard-wrapper">
            {/* <h4 className="mb-3 font-weight-bold text-success">Profile Update Picture</h4> */}
         </div>
      </div>
      <div className="row mb-4">
         <div className="col-sm-12">
            <div className="card">
               <div className="card-body">
                  {/* <form className="needs-validation"> */}
                     
                     <div className="row">
                        <div className="col-md-12 text-center">
                        <h3 className="card-title text-defult font-weight-bold">THANK YOU FOR YOUR PAYMENT</h3>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-md-12 mt-2 text-center">
                           <input type="button" className="mt-1 btn btn-primary" value="<< BACK TO HOME" onClick={()=>{
                            let path = `/dashboard`;
                            navigate(path);
                           }} />  
                           {/* disabled={isbtn}  */}
                        </div>
                     </div>
                  {/* </form> */}
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</>
);
}
export default Thankyoucontent;
