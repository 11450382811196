import react from "react";
import Examheader from "../include/Examheader";
import PracticeSetShuffleContent from "./../content/PracticeSetShuffleContent";

function PracticeSetShuffle() {
    return(
        <>
        <Examheader />
        <div className="wrapper"> 
            <PracticeSetShuffleContent />        
        </div>
        </>
    );
}

export default PracticeSetShuffle;