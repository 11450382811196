import react from "react";
import Header from "./../include/Header";
import Sidebar from "./../include/Sidebar";
import Successstorycontent from "./../content/Successstorycontent";

function Successstory() {
    return(
        <>
        <Header />
        <div className="wrapper"> 
            <Sidebar />
            <Successstorycontent />        
        </div>
        </>
    );
}

export default Successstory;