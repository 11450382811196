import react, { useRef, useEffect, useState } from "react";
import axios from 'axios';

import {Link} from 'react-router-dom';

import dateFormat from 'dateformat';

// import DateCountDown from 'react-date-countdown-timer';

// For Dashboard Loader
import DashboardLoader from '../include/DashboardLoader';
// For Dashboard Loader
// For Noticebaord Loader
import NoticeboardLoader from '../include/NoticeboardLoader';
// For Noticebaord Loader

import { useParams } from "react-router-dom";

import {useNavigate} from "react-router-dom";

import {decode as base64_decode, encode as base64_encode} from 'base-64';

function StartPracticeSetContent(props) {

  const params=useParams();
  const navigate = useNavigate();

    const txtblue={
      color: '#1500b2',
      fontWeight: '700',
      fontSize: 'medium'
    };

    const txtwhite={
      color: '#000',
      fontWeight: '700',
      fontSize: 'medium'
    };

    let [isbtn,setIsbtn]=useState(true);

    let [studname,setStudname]=useState('');
    let [enrollid,setEnrollid]=useState('');
    let [studstatus,setStudstatus]=useState('');
    let [batchname,setBatchname]=useState('');

    //LOADER
    let [loader,setLoader]=useState(false);
    //LOADER

    let [pic,setPic]=useState(null);

    // if (localStorage.getItem("practicesetid")!=null && localStorage.getItem("practicesetid")!=base64_decode(params.practicesetid)) {
    //     localStorage.removeItem("practicesetid");
    //     let path = `/dashboard`;
    //     navigate(path);
    // }

    async function getToken() {
      setLoader(true);

      var tokenval=localStorage.getItem("tokenID");
      if (tokenval!=null) {
        var resp=await fetch("https://safalata.net/safalatalara/api/gettoken", {
          headers: {
            "Content-Type":"application/json",
            "Authorization":"Bearer "+tokenval
          }
        });
        var data=await resp.json();
        setStudname(data.name);
        setEnrollid(data.enroll_id);
        setStudstatus(data.status);
        setPic(data.pic);

        if (data.name.length>1 && localStorage.getItem("enrollid2")==null) {
          localStorage.setItem("enrollid2",data.enroll_id);
        }

        var fd=new FormData();
        fd.append("batchid",data.batch_slno);
        var resp2=await axios.post("https://safalata.net/safalatalara/api/getbatchname",fd);
        var data2=resp2.data;
        setBatchname(data2);

        setLoader(false);

      } else {
        // window.location.href="/";
        let path = `/`;
        navigate(path);
      }

    }

    
    //PRACTICE EXAM DETAILS
    let [examname,setExamname]=useState("");
    let [examtotalqst,setExamtotalqst]=useState("");
    let [examstdate,setExamstdate]=useState("");
    let [exameddate,setExameddate]=useState("");
    let [shuffle,setShuffle]=useState("");

    async function practicesetdetails_mock() {
      setLoader(true);

      let practicesetid=localStorage.getItem("practicesetid");
      var fd=new FormData();
      fd.append("practicesetid",practicesetid);
      var resp=await axios.post("https://safalata.net/safalatalara/api/practicesetdetails_mock",fd);
      var data=resp.data;
      setExamname(data.practicesetname);
      setExamtotalqst(data.qcount);
      // setShuffle(data.practiceexamsetselection_shuffle);

      localStorage.setItem("examname",data.practicesetname);
      localStorage.setItem("exameddate",data.eddate);
      localStorage.setItem("qcount",data.qcount);

      setLoader(false);
      setIsbtn(false);

      // localStorage.setItem("shuffle",data.shuffle);
    }

    let [noticename, setNoticename]=useState("");
    let [noticedatetime, setNoticedatetime]=useState("");

    async function getnoticeboard() {
        var resp=await axios.get("https://safalata.net/safalatalara/api/getnoticeboard");
        var data=resp.data;
        setNoticename(data.noticename);
        setNoticedatetime(data.noticedatetime);
    }

    useEffect(()=>{
      getToken();
    //   practiceexamdetails();

      getnoticeboard();
      practicesetdetails_mock();
      

    },[])

    return(
        <>        
        
        <div id="content">
            <div className="p-4 contentWrapper">
                <div className="row">
                    <div className="col-lg-12 dasboard-wrapper">
                    <h4 className="mb-3 font-weight-bold text-success">Dasboard</h4>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-sm-7">
                    {!loader?
                        <p className="dashboardtxt">
                          {pic!=null?<img src={"https://safalata.net/safalatalara/uploadpic/"+pic} alt="" className="profile_img rounded border border-success" style={{float: 'left', width: '100px', marginRight: 10, borderRadius: '5px'}} />:''}
                          <span style={{fontSize: 'x-large', color: '#870017', textTransform: 'uppercase'}}>{studname}</span><br/>
                          Batch Name : <span style={txtblue}>{batchname}</span><br/>
                          Enrollment ID : <span style={txtblue}>{localStorage.getItem("enrollid2")}</span><br/>
                          Student Status : {studstatus==1?<span className="text text-success">ACTIVE</span>:<span className="text text-danger">INACTIVE</span>}
                        </p>                        
                        
                    :<DashboardLoader/>}
                    </div>
                    {/* <div className="col-sm-3">
                      
                    </div> */}
                    <div className="col-sm-5">
                    {!loader?
                        <h6 className="card-title text-defult font-weight-bold">
                          <span style={{fontWeight:700, fontSize: 'large'}}>Notice Board</span><br/><br/>
                          <span style={{fontWeight:600, fontSize: 'medium'}} className="text text-danger">Notice : {noticename}. ( Date : {dateFormat(noticedatetime, "mmmm dd, yyyy HH:MM")} )</span><br/>
                          {/* <span style={{fontWeight:700, fontSize: 'medium'}}>Date : {dateFormat(noticedatetime, "mmmm dd, yyyy HH:MM")}</span> */}
                        </h6>
                        // <p>Notice : # Next new batch (Batch-13) is commenced from 8th January 2022 at 12.30 p.m. # We commence new batch in two different sessions - January (1st week) and July (1st Week). # This is 12 months course.
                        //  Date : 20-08-2021</p>
                    :<NoticeboardLoader/>}
                    </div>
                </div>

                <div className="row mb-4">
                     <div className="col-sm-12">
                        
                        <div className="bg_white border rounded" style={{padding: '20px'}}>   

                          <h6 className="card-title text-defult font-weight-bold text-center" style={{fontSize: 'x-large'}}>GO TO TEST YOUR PRACTICE ONLINE SET</h6>    
                          
                              
                          <h2 className="startexamh2txt2">This is Multiple Choice Practice Set To Test Your Knowledge</h2>

                          <div className="startexamh2txt2">Practice Exam Set Name : <span className="startspantxt">{examname}</span></div>

                          <h2 className="startexamh2txt2">Number of Questions : {examtotalqst}</h2>

                          <h2 className="startexamh2txt2">Question Type : Multiple Choice</h2>

                          <h2 className="startexamh2txt2">Shuffling Mode : {localStorage.getItem("shuffle")==1? "Full Shuffling" : localStorage.getItem("shuffle")==2? "Partial Shuffling" : "Non Shuffling"}</h2>

                          <div className="text-center">
                            {/* <Link to="/practiceexam/1" className="btn btn-success startexambtn">Start Exam</Link> */}
                            <button className="btn btn-success startexambtn" onClick={async ()=>{
                            console.log(localStorage.getItem("shuffle"));
                        
                            // if (localStorage.getItem("shuffle")==2) {
                            var fd=new FormData();
                            fd.append("practicesetid",localStorage.getItem("practicesetid"));
                            fd.append("enrollid",localStorage.getItem("enrollid2"));
                            var resp=await axios.post("https://safalata.net/safalatalara/api/addstartpracticesetschedule",fd);
                            var data=resp.data;
  
                            if (localStorage.getItem("shuffle")==2) {
                              localStorage.setItem("querypagecount",0);
  
                              localStorage.setItem("exameddate",data.eddate);
                              let path = `/practicesetshuffle/1`;
                              navigate(path);
                            } else {
                              localStorage.setItem("querypagecount",0);
  
                              localStorage.setItem("exameddate",data.eddate);
                              let path = `/practiceset/1`;
                              navigate(path);
                            }
                            // } else {
                            //   let path = `/practiceset/1`;
                            //   navigate(path); 
                            // }
                          }} disabled={isbtn}>Start Practice Set</button>
                          </div>

                        </div>
                    </div>
                </div>
           

            </div>  
        </div>
       
        </>
    );
}

export default StartPracticeSetContent;