import react, { useRef, useEffect, useState } from "react";
import axios from 'axios';

import dateFormat from 'dateformat';

// import DateCountDown from 'react-date-countdown-timer';

// For Dashboard Loader
import DashboardLoader from '../include/DashboardLoader';
// For Dashboard Loader

// For Noticebaord Loader
import NoticeboardLoader from '../include/NoticeboardLoader';
// For Noticebaord Loader

import {useNavigate} from "react-router-dom";

import {decode as base64_decode, encode as base64_encode} from 'base-64';

// FOR RAZORPAY PAYMENT GATE INTEGRATION
import $ from 'jquery';
import useRazorpay, { RazorpayOptions } from "react-razorpay";
// FOR RAZORPAY PAYMENT GATE INTEGRATION

import React from 'react';

import Modal from 'react-modal';

import { useParams } from 'react-router-dom';


  function ExamPracticeSetListContent(props) {

      const Razorpay = useRazorpay();
      let navigate = useNavigate();

      const params=useParams();

      // For Modal
        const customStyles = {
            content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            },
        };

        const formgroup={
            margin: '10px',
            fontWeight: 700
        };

        let subtitle;
        const [modalIsOpen, setIsOpen] = React.useState(false);

        function openModal() {
            setIsOpen(true);
        }

        function afterOpenModal() {
            subtitle.style.color = '#f00';
        }

        function closeModal() {
            setIsOpen(false);
        }
        // FOr Modal

      const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
      };

      const txtred={
          color: '#c70000',
          fontWeight: '700',
          fontSize: 'medium'
      };

      const txtblue={
        color: '#1500b2',
        fontWeight: '700',
        fontSize: 'medium'
      };

      const txtpayred={
        color: '#d92400',
        fontWeight: '500',
        fontSize: 'small'
      };

      const txtwhite={
        color: '#000',
        fontWeight: '700',
        fontSize: 'small'
      };

      const txtwhite2={
        color: '#000',
        fontWeight: '700',
        fontSize: 'smaller'
      };

      // const baseurl="https://www.safalata.net/safalatalara";

      let [isbtn,setIsbtn]=useState(true);
      //localStorage.setItem("studid",1000);

      // if (localStorage.getItem("studtoken")==null) {
      //   window.location.href="/";
      // }

      let [studname,setStudname]=useState('');
      let [enrollid,setEnrollid]=useState('');
      let [studstatus,setStudstatus]=useState('');
      let [batchname,setBatchname]=useState('');
      let [pic,setPic]=useState(null);

      //today date
      const now = new Date();
      const curdate=dateFormat(now, "yyyy-mm-dd");


      //LOADER
      let [loader,setLoader]=useState(false);
      //LOADER


      // async function getToken() {

      //   setLoader(true);

      //   var tokenval=localStorage.getItem("tokenID");
      //   if (tokenval!=null) {
          
      //     var resp=await fetch("https://safalata.net/safalatalara/api/gettoken", {
      //       headers: {
      //         "Content-Type":"application/json",
      //         "Authorization":"Bearer "+tokenval
      //       }
      //     });
      //     var data=await resp.json();
      //     setStudname(data.name);
      //     setEnrollid(data.enroll_id);
      //     setStudstatus(data.status);
      //     setPic(data.pic);

      //     var fd=new FormData();
      //     fd.append("batchid",data.batch_slno);
      //     var resp2=await axios.post("https://safalata.net/safalatalara/api/getbatchname",fd);
      //     var data2=resp2.data;
      //     setBatchname(data2);

      //     setLoader(false);

      //   } else {
      //     window.location.href="/login";
      //   }

      // }

      async function getToken() {
        var tokenval=localStorage.getItem("tokenID");
        if (tokenval!=null) {
        
        var resp=await fetch("https://safalata.net/safalatalara/api/gettoken", {
           headers: {
              "Content-Type":"application/json",
              "Authorization":"Bearer "+tokenval
           }
        });
        var data2=await resp.json();
        localStorage.setItem("enrollid2", data2.enroll_id);
        localStorage.setItem("name", data2.name);
        localStorage.setItem("status", data2.status);
        localStorage.setItem("batchid",data2.batch_slno);
        localStorage.setItem("pic",data2.pic);
        localStorage.setItem("usertoken",data2.usertoken);
        setIsbtn(true);
           // let path = `/dashboard`; 
           // navigate(path);
       
        }
      }

      if (localStorage.getItem("tokenID")==null) {
          // window.location.href="/";
            let path = `/`; 
            navigate(path);
      }

      async function getBatchname() {
          // console.log(localStorage.getItem("pic"));
          setLoader(true);
          // var fd=new FormData();
          // fd.append("batchid",localStorage.getItem("batchid"));
          // var resp2=await axios.post("https://safalata.net/safalatalara/api/getbatchname",fd);
          // var data2=resp2.data;
          // setBatchname(data2);
          setPic(localStorage.getItem("pic"));
          setStudname(localStorage.getItem("name"));
          setEnrollid(localStorage.getItem("enrollid2"));
          setStudstatus(localStorage.getItem("status"));
          setLoader(false);

          // console.log(localStorage.getItem("enrollid2"));
      }

      //UPCOMING LIVE EXAM LIST
      let [upcominglivearr,setUpcominglivearr]=useState([]);

      async function upcomingliveexam() {
        // console.log(enrollid);
        // console.log(curdate);
        // let enrollid=localStorage.getItem("enrollid2");
        var fd=new FormData();
        fd.append("enrollid",localStorage.getItem("enrollid2"));
        fd.append("curdate",curdate);
        var resp=await axios.post("https://safalata.net/safalatalara/api/upcomingliveexam_newsession",fd);
        var data=resp.data;
        setUpcominglivearr(data);
      }


      //UPCOMING PRACTICE EXAM LIST
      let [upcomingpracticearr,setUpcomingpracticearr]=useState([]);

      async function upcomingpracticeexam() {
        var fd=new FormData();
        fd.append("enrollid",localStorage.getItem("enrollid2"));
        fd.append("curdate",curdate);
        var resp=await axios.post("https://safalata.net/safalatalara/api/upcomingpracticeexam_newsession",fd);
        var data=resp.data;
        setUpcomingpracticearr(data);
      }

      // console.log(localStorage.getItem("enrollid2"));
      // console.log(curdate);


      function getNostorage() {
        if (localStorage.getItem("livesetid")!=null) {
          localStorage.removeItem("livesetid");
        }
      }

      let [noticename, setNoticename]=useState("");
      let [noticedatetime, setNoticedatetime]=useState("");
      let [fees, setFees]=useState("");

      async function getnoticeboard() {
          setLoader(true);
          var resp=await axios.get("https://safalata.net/safalatalara/api/getnoticeboard");
          var data=resp.data;
          setNoticename(data.noticename);
          setNoticedatetime(data.noticedatetime);
          setFees(data.fees);
          setLoader(false);
      }

      let [orderstatus, setOrderstatus]=useState("");
      async function getstudlogincheck() {
          var fd=new FormData();
          fd.append("enrollid",localStorage.getItem("enrollid2"));
          var resp=await axios.post("https://safalata.net/safalatalara/api/studlogincheck",fd);
          var data=resp.data;
          setOrderstatus(data);
          // console.log(data);
      }

      let [loader2,setLoader2]=useState(false);
      let [btnpaytxt,setBtnpaytxt]=useState("");

      //PAYMENT GATEWAY RAZORPAY INTEGRATION
      function pay_online(oid,st,name,enrollid) {
        // setBtnpaytxt("PLEASE WAIT...");
        var name=name;
        var amt=Number(st);
        // var eid=enrollid;
        // setLoader2(true);
        
        $.ajax({
              type:'post',
              url:'https://safalata.net/safalatalara/api/gopayment',
              data:"amt="+amt+"&name="+name,
              success:function(result){
                  var options = {
                        "key": "rzp_live_QrhcwcL0TIPoRe", 
                        // "key": "rzp_test_3pFj1UUL1ja69Q",
                        "amount": amt*100, 
                        "currency": "INR",
                        "name": "Safalata - Shraddha Group of Education",
                        "description": "Exam Fees",
                        "image": "https://www.safalata.net/safalatalara/payimage/payimg.jpg",
                        "handler": function (response){
                          $.ajax({
                              type:'post',
                              url:'https://safalata.net/safalatalara/api/gopayment',
                              data:"payment_id="+response.razorpay_payment_id+"&oid="+oid+"&enrollid="+enrollid+"&amt="+amt,
                              success:function(result){
                                setBtnpaytxt("Please wait .. it will be redirected to thank you page.")
                                //    window.location.href="/";
                                // let path = `/order/`+oid;
                                // if (response==1) {
                                  // setBtnpaytxt("PAY NOW");
                                  let path = `/thankyou`;
                                  navigate(path);
                                // } else {
                                //   // setBtnpaytxt("PAY NOW");
                                // }
                                
                              }
                          });
                        }
                    };
                    var rzp1 = new Razorpay(options);
                    rzp1.open();
                }
        });
        // setLoader2(false);
        
      }


  let [endpaymentdate,setEndpaymentdate]=useState("");

  let [btnpay,setBtnpay]=useState(true);

  async function getTimeInterval() {

      var fd=new FormData();
      fd.append("enrollid",localStorage.getItem("enrollid2"));
      var resp=await axios.post("https://safalata.net/safalatalara/api/endpaymentdate",fd);
      var data=resp.data;
      setEndpaymentdate(data.end_payment_date);
      // console.log('hi '+data.end_payment_date);
      
  }

      


      // TIME INTERVAL COUNTDOWN
      // const [timerday,setTimerday]=useState("00");
      // const [timerhour,setTimerhour]=useState("00");
      // const [timermin,setTimermin]=useState("00");
      // const [timersec,setTimersec]=useState("00");

      const [datetimeinterval,setDateTimeInterval]=useState("");

      // const [enddate,setEnddate]=useState("");

      // let x=0;

      let interval = useRef();

      const startTimer = (endeddate) => {
          const coundownDate = new Date(endeddate).getTime();

          interval=setInterval(()=>{
              const now = new Date().getTime();
              const distance = coundownDate-now;

              const days=Math.floor(distance / (1000*60*60*24));
              const hours=Math.floor((distance % (1000*60*60*24) / (1000*60*60)));
              const mins=Math.floor((distance % (1000*60*60)) / (1000*60));
              const secs=Math.floor((distance % (1000*60)) / 1000);

              if (distance < 0) {
                  //stop timer code
                  clearInterval(interval);
                  setDateTimeInterval("Schedule End");
                  // setIsradio(true);
                  // setIsskipbtn(true);
                  // autosubmitscore();
                  // setIsbtn(false);

                  // logintokencheck();




                  
                  // setBtnpay(false);

              } else {
                  // setDateTimeInterval(days+"d "+hours+"h "+mins+"m "+secs+"s");
                  setDateTimeInterval(days+" Days");
                  // setIsradio(false);
                  // setIsbtn(true);

                  // logintokencheck();

                  // console.log(days);

                  if (days<8) {
                    setBtnpay(false);
                  }

              }
          
          }, 1000)
      };
      // TIME INTERVAL COUNTDOWN

      //UPCOMING PRACTICE EXAM LIST
        // let [loader,setLoader]=useState(false);
        let [practicesetarr,setPracticesetarr]=useState([]);
        let [countexamallot,setCountexamallot]=useState("");
        let [parentpackagename,setParentpackagename]=useState("");
        let [subpackagename,setSubpackagename]=useState("");
        let [examcount,setExamcount]=useState("");

        //today date
        // const now = new Date();
        // const curdate=dateFormat(now, "yyyy-mm-dd");

        async function packagewisepracticesetlist() {
        setLoader(true);
        var fd=new FormData();
        fd.append("packageid",params.packageid);
        fd.append("subpackageid",params.subpackageid);
        fd.append("enrollid",localStorage.getItem("enrollid2"));
        var resp=await axios.post("https://safalata.net/safalatalara/api/packagewisepracticesetlist",fd);
        var data=resp.data;
        setPracticesetarr(data.exam);
        setCountexamallot(data.count_exam_allot);
        setParentpackagename(data.package_name);
        setSubpackagename(data.subpackage_name);
        setExamcount(data.examcount);
        setLoader(false);
        }

        // let [orderstatus, setOrderstatus]=useState("");
        // async function getstudlogincheck() {
        //     var fd=new FormData();
        //     fd.append("enrollid",localStorage.getItem("enrollid"));
        //     var resp=await axios.post("https://safalata.net/safalatalara/api/studlogincheck",fd);
        //     var data=resp.data;
        //     setOrderstatus(data);
        // }

        const [open, setOpen] = useState(false);
        const handleOpen = () => setOpen(true);
        const handleClose = () => setOpen(false);

        let openmsg="This Practice Set Will Soon.....";
      

      useEffect(()=>{
        // getToken();


        // console.log(localStorage.getItem("exammode"));
        
        getBatchname();
        upcomingliveexam();
        upcomingpracticeexam();
        getNostorage();

        getnoticeboard();

        getstudlogincheck();

        getToken();

        getTimeInterval();

        packagewisepracticesetlist();

        // getTimeLoader();
        
      },[])

      return(
        <>        
        
        <div id="content">
            <div className="p-4 contentWrapper">
                <div className="row">
                    <div className="col-lg-12 dasboard-wrapper">
                    <h4 className="mb-3 font-weight-bold text-success">Dasboard</h4>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-sm-7">
                    {!loader?
                        <p className="dashboardtxt">
                          {/* {localStorage.getItem("pic").length>1?
                          <img src={"https://safalata.net/safalatalara/uploadpic/"+localStorage.getItem("pic")} alt="" className="profile_img rounded border border-success" style={{float: 'left', width: '100px', marginRight: 10, borderRadius: '5px'}} />:''} */}
                          <span style={{fontSize: 'large', color: '#870017', textTransform: 'uppercase'}}>{studname}</span><br/>
                          {/* Batch Name : <span style={txtblue}>{batchname}</span><br/> */}
                          Enrollment ID : <span style={txtblue}>{enrollid}</span><br/>
                          Student Status : {studstatus==1?<span className="text text-success">ACTIVE</span>:<span className="text text-danger">INACTIVE</span>}
                        </p>                        
                        // <p className="dashboardtxt">{studname}</p>
                        // <p className="dashboardtxt">Batch Name : {batchname}</p>
                        // <p className="dashboardtxt">Enrollment ID : {enrollid}</p>
                        // <p className="dashboardtxt">Student Status : {studstatus==1?'ACTIVE':'INACTIVE'}</p>
                    
                    :<DashboardLoader/>}
                    </div>

                    

                    {/* <div className="col-sm-3">
                      
                    </div> */}
                    <div className="col-sm-5">
                    {!loader?
                        <h6 className="card-title text-defult font-weight-bold">
                          <span style={{fontWeight:700, fontSize: 'large'}}>Notice Board</span><br/><br/>
                          <span style={{fontWeight:600, fontSize: 'medium'}} className="text text-danger">Notice : {noticename}. ( Date : {dateFormat(noticedatetime, "mmmm dd, yyyy HH:MM")} )</span><br/>
                          {/* <span style={{fontWeight:700, fontSize: 'medium'}}>Date : {dateFormat(noticedatetime, "mmmm dd, yyyy HH:MM")}</span> */}
                        </h6>
                        // <p>Notice : # Next new batch (Batch-13) is commenced from 8th January 2022 at 12.30 p.m. # We commence new batch in two different sessions - January (1st week) and July (1st Week). # This is 12 months course.
                        //  Date : 20-08-2021</p>
                    :<NoticeboardLoader/>}


                    </div>

                </div>


                <div className="row mb-4">
                     <div className="col-sm-12">
                         <h6 className="card-title text-defult font-weight-bold" style={txtwhite}> </h6>
                         <div className="bg_white border rounded">            
                            <table className="table">
                                <thead className="thead-bg">
                                  <tr>                                    
                                    <th colSpan={2} style={txtwhite}>
                                        {"<"}{subpackagename}{">"} : Practice Set
                                    </th>                              
                                  </tr>
                                </thead>
                                <thead className="thead-dark">
                                  <tr>
                                    <th>Practice Set</th>
                                    <th style={{textAlign: 'center'}}>Go Exam</th>
                                  </tr>
                                </thead>
                                <tbody>
                                
                                {countexamallot>=1? 
                                    practicesetarr.map((e)=>
                                    <tr key={e.practiceset_slno}>

                                        <td style={txtwhite}>{e.practiceset_name}</td>
                                        <td style={{textAlign: 'center'}}><button className="btn btn-success" onClick={()=>{

                                                if (e.imported_qa>0) {
                                                    localStorage.setItem("shuffle",e.practiceexamsetselection_shuffle);
                                                    console.log(localStorage.getItem("shuffle"));
                                                    localStorage.setItem("practicesetid",e.practiceset_slno);
                                                    let path = `/startpracticeset`;
                                                    navigate(path);
                                                } else {
                                                    handleOpen();
                                                }

                                        }}>Go Exam</button></td>
                                    </tr>
                                    )
                                :
                                countexamallot==0?
                                <tr>
                                    <td style={txtwhite} colSpan={2}>
                                    No Exam Found
                                    </td>
                                </tr>
                                :
                                ''
                                }

                                </tbody>
                              </table>
                        </div>
                    </div>
                </div>

                {/* :
                <div>
                <div className="row mb-4">
                     <div className="col-sm-12">
                         <h6 className="card-title text-defult font-weight-bold" style={txtwhite}><i classname="fa fa-seedling"></i> Upcoming Live Set Exam <button className="btn btn-default" onClick={()=>{
                           upcomingliveexam();
                         }}><i className="fas fa-sync"></i></button></h6>
                         <div className="bg_white border rounded">            
                            <table className="table">
                                <thead className="thead-dark">
                                  <tr>
                                    
                                    <th>Live Exam</th>
                                    <th>Exam Date</th>
                                  
                                    <th>Time</th>
                                    <th className="text-center">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                      
                                 
                                {orderstatus==1?
                                  upcominglivearr.map((e)=>
                                  <tr key={e.liveset_slno}>

                                  <td style={txtwhite}>{e.liveset_name}</td>
                                  <td style={txtwhite}>{dateFormat(e.liveexamsetselection_start_schedule, "dd-mm-yyyy")}</td>
                                  <td style={txtwhite}>{dateFormat(e.liveexamsetselection_start_schedule, "HH:MM TT")}-{dateFormat(e.liveexamsetselection_end_schedule, "HH:MM TT")}</td>
                                  <td className="text-center">
                                  {(new Date(dateFormat(e.liveexamsetselection_start_schedule, "mmmm dd, yyyy HH:MM:00")).getTime())-(new Date().getTime())<=0?
                                    e.existscorecount==0?
                                    <button className="btn btn-success" onClick={()=>{
                                      localStorage.setItem("livesetid",e.liveset_slno);
                                      let path = `/startliveexam/`;
                                      navigate(path);
                                    }}>Go Exam</button>
                                    
                                    :
                                    <button className="btn btn-danger" disabled='true' onClick={()=>{
                                      
                                    }}>Exam Done</button>
                                    
                                  :
                                    <button className="btn btn-info" disabled={isbtn} onClick={()=>{
                                      
                                    }}>Exam Soon</button>}
                                  
                                  </td>
                                  </tr>
                                  )
                                :
                                ''
                                }

                                </tbody>
                              </table>
                        </div>
                    </div>
                </div>


                <div className="row mb-4">
                     <div className="col-sm-12">
                         <h6 className="card-title text-defult font-weight-bold" style={txtwhite}><i classname="fa fa-seedling"></i> Upcoming Practice Set Exam Upcoming Live Set Exam <button className="btn btn-default" onClick={()=>{
                           upcomingpracticeexam();
                         }}><i className="fas fa-sync"></i></button></h6>
                         <div className="bg_white border rounded table-responsive">            
                            <table className="table table-striped table-bordered">
                                <thead className="thead-dark">
                                  <tr>
                                    <th>Practice Exam</th>
                                    <th>Exam Date</th>
                                    <th>Time</th>
                                    <th className="text-center">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                      
                                {orderstatus==1?
                                  upcomingpracticearr.map((e)=>
                                  <tr key={e.practiceset_slno}>

                                  <td style={txtwhite2}>{e.practiceset_name}</td>
                                  <td style={txtwhite}>{dateFormat(e.practiceexamsetselection_start_schedule, "dd-mm-yyyy")}</td>
                                  <td style={txtwhite}>{dateFormat(e.practiceexamsetselection_start_schedule, "HH:MM TT")}-{dateFormat(e.practiceexamsetselection_end_schedule, "HH:MM TT")}</td>
                                  <td className="text-center">

                                   {(new Date(dateFormat(e.practiceexamsetselection_start_schedule, "mmmm dd, yyyy HH:MM:00")).getTime())-(new Date().getTime())<=0?
                                    <button className="btn btn-success" onClick={()=>{
                                      
                                      let path = `/startpracticeexam/`+base64_encode(e.practiceset_slno);
                                      navigate(path);
                                    }}>Go Exam</button>
                                    :
                                    <button className="btn btn-info" disabled={isbtn} onClick={()=>{
                                      
                                    }}>Exam Soon</button>}
                                  
                                  </td>
                                  </tr>
                                  )
                                  :
                                  ''
                                  }
                                

                                </tbody>
                              </table>
                        </div>
                    </div>
                </div>
                </div> */}
                
           

            </div>  
        </div>


        <Modal
            // open={open3}
            // onClose={handleClose3}
            // aria-labelledby="modal-modal-title"
            // aria-describedby="modal-modal-description"
            // className='hbtn btn-bluenoticeboard'
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            // onRequestClose={handleClose}
            style={customStyles}
            contentLabel="Example Modal"
        >
            
            <div className="text-red mt-1 mb-2">
                {openmsg}
            </div>
            <div textAlign="center" className='btnRow text-center'>
                <button className="btn-info" onClick={closeModal}>Close</button>  
            </div>     
       
        </Modal>

       
        </>
    );
}

export default ExamPracticeSetListContent;