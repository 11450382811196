import {Link} from 'react-router-dom';

import react, { useRef, useEffect, useState } from "react";
import axios from 'axios';

function Passwordchangecontent() {

   if (localStorage.getItem("tokenID")==null) {
      window.location.href="/login";
   }

   let [password,setPassword]=useState("");
   let [password2,setPassword2]=useState("");

   let [passworderr,setPassworderr]=useState("");

   let [msg,setMsg]=useState("");
   let [msgcode,setMsgcode]=useState("");
   let [msgcolor,setMsgcolor]=useState("");

   function getNostorage() {
      if (localStorage.getItem("livesetid")!=null) {
        localStorage.removeItem("livesetid");
      }
   }

   let [isbtn,setIsbtn]=useState(true);
   // async function getToken() {
   //    var tokenval=localStorage.getItem("tokenID");
   //    if (tokenval!=null) {
   //       var resp=await fetch("https://safalata.net/safalatalara/api/gettoken", {
   //          headers: {
   //             "Content-Type":"application/json",
   //             "Authorization":"Bearer "+tokenval
   //          }
   //       });
   //       var data=await resp.json();

   //    } else {
   //       window.location.href="/";
   //    }
   // }

   async function passwordupdate() {
      var flag=1;
      if (password.length>0 && password2.length>0) {
         if (password.length<6 || password2.length<6 && password!=password2) {
                setPassworderr("Required 6-Alfanumeric/Digits Password");
                flag=0;
         } else if (password.length>=6 && password2.length>=6 && password!=password2) {
                setPassworderr("Password does not match");
                flag=0;
         }
      } else {
                setPassworderr("Required 6-Alfanumeric/Digits Password");
                flag=0;
      }
      if (flag==1) {
         var fd=new FormData();

         fd.append("password",password);
         fd.append("enrollid",localStorage.getItem("enrollid2"));
         var resp=await axios.post("https://safalata.net/safalatalara/api/passwordupdate",fd);
         var data=resp.data;
         setMsg(data.msg);
         setMsgcode(data.msgcode);
         setMsgcolor(data.msgcolor);

         if (data.msgcode==1) {
             setIsbtn(true);
             setPassword("");
             setPassword2("");

             setPassworderr("");

             window.scrollTo({
                 top: 0,
                 behavior: "smooth"
             });
         }
      }
   }

   useEffect(()=>{
      // getToken();
      getNostorage();
   },[])

   return(
   <>        
<div id="content">
   <div className="p-4 contentWrapper">
      <div className="row">
         <div className="col-lg-12 dasboard-wrapper">
            <h4 className="mb-3 font-weight-bold text-success">Change Password</h4>
            {msgcode>0?
               <div className="col-md-12 form-group text-center" style={{marginTop: 40, marginBottom: 30}}>
                  <div style={{fontSize: 'large', fontWeight: 700}} className={msgcolor}>{msg}</div>
               </div>
            :''}
         </div>
      </div>
      <div className="row mb-4">
         <div className="col-sm-1"></div>
         <div className="col-sm-10">
            <div className="card">
               <div className="card-body">
                  <div className="position-relative form-group">
                     <label for="stud_enroll" className="">My Enroll ID</label>
                     <input name="stud_enroll_id" id="stud_enroll_id" type="text" value={localStorage.getItem("enrollid2")} className="form-control" disabled="true" />
                  </div>
                  <div className="position-relative form-group">
                     <label for="new_password" className="">Type New Password</label>
                     <input type="password" defaultValue={password} className="form-control" onChange={(ev)=>{
                                        setPassword(ev.target.value);
                                        if (ev.target.value.length>=6 && password2.length>=6) {
                                            setPassworderr("");
                                            setMsgcode("");
                                        }
                                    }} placeholder="Minimum 6 digits or characters password"  />
                  </div>
                  <div className="position-relative form-group">
                     <label for="new_password" className="">Re-type Password</label>
                     <input type="password" defaultValue={password2} className="form-control" onChange={(ev)=>{
                                        setPassword2(ev.target.value);
                                        if (ev.target.value.length>=6 && password.length>=6) {
                                            setPassworderr("");
                                            setMsgcode("");
                                        }
                                    }} placeholder="Re-type password"  />
                     <div className='text text-danger'>{passworderr}</div>
                  </div>
                  <div className="row">
                     <div className="col-md-12 mt-2">
                        <input type="button" className="mt-1 btn btn-primary" value="Update Password" onClick={passwordupdate} desabled={isbtn} />
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="col-sm-1"></div>
      </div>
   </div>
</div>
</>
);
}
export default Passwordchangecontent;
