import react, { useEffect, useState } from "react";
import axios from 'axios';
import validator from 'validator';
import {Link} from 'react-router-dom';

import {useNavigate} from "react-router-dom";
// import ForgotPassword from "../pages/ForgotPassword";

function Updatepasswordcontent() {

  let navigate = useNavigate();

  // const baseurl="https://www.safalata.in/safalatalara";

  if (!localStorage.getItem("phone")) {
    window.location.href="/";
  }

  let [password,setPassword]=useState("");
  let [password2,setPassword2]=useState("");

  let [passworderr,setPassworderr]=useState("");

  let [isbtn,setIsbtn]=useState(true);

  // VALIDATION
  

  // MSG
  let [msg,setMsg]=useState("");
  let [msgcode,setMsgcode]=useState("");
  let [msgcolor,setMsgcolor]=useState("");
  // MSG

  async function passwordupdatebyphone() {
    var flag=1;
    if (password.length>0 && password2.length>0) {
       if (password.length<6 || password2.length<6 && password!=password2) {
              setPassworderr("Required 6-Alfanumeric/Digits Password");
              flag=0;
       } else if (password.length>=6 && password2.length>=6 && password!=password2) {
              setPassworderr("Password does not match");
              flag=0;
       }
    } else {
              setPassworderr("Required 6-Alfanumeric/Digits Password");
              flag=0;
    }
    if (flag==1) {
       var fd=new FormData();

       fd.append("password",password);
       fd.append("phone",localStorage.getItem("phone"));
       var resp=await axios.post("https://safalata.net/safalatalara/api/passwordupdatebyphone",fd);
       var data=resp.data;
       setMsg(data.msg);
       setMsgcode(data.msgcode);
       setMsgcolor(data.msgcolor);

       if (data.msgcode==1) {
           setIsbtn(true);
           setPassword("");
           setPassword2("");

           setPassworderr("");

           window.scrollTo({
               top: 0,
               behavior: "smooth"
           });

           setTimeout(()=>{
                localStorage.removeItem('phone');
                let path = `/`; 
                navigate(path);
           }, 2000);
       }
    }
 }


  return(
    <>
    <div className="loginBoxWrap">
        <div className="loginBox">
          <div className="logoRow"><img src="images/logo2.png" alt="" /></div>
        	{/* <div className="bg_white2" style={{background: '#030088'}}> */}
          <div className="bg_white2" style={{background: '#fff'}}>
          <h2 style={{fontSize: 'medium'}}>Shraddha Group of Education</h2>
          <div className="loginBoxContener p-4">
            <p className="text-center" style={{color: '#000',fontSize: 'large'}}>Update Password</p>
                {msgcode>0?
                <div className="col-md-12 form-group text-center" style={{marginTop: 40, marginBottom: 0}}>
                  <div style={{fontSize: 'large', fontWeight: 700}} className={msgcolor}>{msg}</div>
                </div>
                :''}
              <div className="form-group row">
                {/* <label for="inputEmail3" className="col-sm-12 col-form-label">Login ID</label>
                <div className="col-sm-12">
                  <input type="email" className="form-control" placeholder="Registered Email" onChange={(ev)=>{
                    setLoginid(ev.target.value);
                    if (validator.isEmail(loginid)) {
                      setIsrrloginid(false);
                    }
                    setAlert(false);
                  }}/>
                  {iserrloginid?<div className="text text-danger">{errloginid}</div>:''}
                </div> */}
                {/* <label for="inputPhone3" className="col-sm-12 col-form-label2">Registered Phone Number</label> */}
                <label for="inputPhone3" className="col-sm-12" style={{color: '#fff'}}>Registered Phone Number</label>
                <div className="col-sm-12">
                    <label for="new_password" className="">Type New Password</label>
                    <input type="password" defaultValue={password} className="form-control" onChange={(ev)=>{
                        setPassword(ev.target.value);
                        if (ev.target.value.length>=6 && password2.length>=6) {
                            setPassworderr("");
                            setMsgcode("");
                        }
                    }} placeholder="Minimum 6 digits or characters password"  />
                </div>
              </div>
              <div className="form-group row">
                <label for="inputPassword3" className="col-sm-12 col-form-label" style={{color: '#fff'}}>Password</label>
                <div className="col-sm-12">
                    <label for="new_password" className="">Re-type Password</label>
                    <input type="password" defaultValue={password2} className="form-control" onChange={(ev)=>{
                        setPassword2(ev.target.value);
                        if (ev.target.value.length>=6 && password.length>=6) {
                            setPassworderr("");
                            setMsgcode("");
                        }
                    }} placeholder="Re-type password"  />
                     <div className='text text-danger'>{passworderr}</div>
                </div>
              </div>

              {/* <div className="form-group row">
                <div className="col-sm-12">
                  <div className="form-check small">
                    <input className="form-check-input" type="checkbox" id="gridCheck1" onChange={(ev)=>{
                      if (ev.target.checked) {
                        setIsbtn(false);
                      } else {
                        setIsbtn(true)
                      }
                    }} />
                    <label className="form-check-label " for="gridCheck1"> Remember Me </label>
                  </div>
                </div>
              </div> */}

              <div className="form-group row text-center">
                <div className="col-sm-12">
                  <div className="form-check p-0">
                    <button className="btn btn-primary" onClick={passwordupdatebyphone} desabled={isbtn}>Update Password</button>
                  </div>
                </div>
              </div>
              <div className="form-group row text-center">
                <div className="col-sm-12">
                  <div className="form-check p-0">
                  <Link to="/">CLICK HERE</Link> To Login Your Account
                  </div>
                </div>
              </div>

          </div>
          </div>
         <div className="small mt-2 copyrighttext">&copy; Copyright 2022. All Rights Reserved.<br/>Powered By <a href="http://www.cheapwebguru.com" target="_blank">CHEAPWEBGURU.COM</a>.<br></br></div>
      </div>   
    </div>
    
    </>

  );
}

export default Updatepasswordcontent;







