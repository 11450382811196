import {Link} from 'react-router-dom';

import react, { useRef, useEffect, useState } from "react";
import axios from 'axios';

function Updateprofilepiccontent() {

   if (localStorage.getItem("tokenID")==null) {
      window.location.href="/login";
   }

   function getNostorage() {
      if (localStorage.getItem("livesetid")!=null) {
        localStorage.removeItem("livesetid");
      }
   }

   let [msg,setMsg]=useState("");
   let [msgcode,setMsgcode]=useState("");
   let [msgcolor,setMsgcolor]=useState("");

   let [pic,setPic]=useState(null);
   let [newpic,setNewpic]=useState(null);

   let [isbtn,setIsbtn]=useState(true);
   
   async function getToken() {
      var tokenval=localStorage.getItem("tokenID");
      if (tokenval!=null) {
         var resp=await fetch("https://safalata.net/safalatalara/api/gettoken", {
            headers: {
               "Content-Type":"application/json",
               "Authorization":"Bearer "+tokenval
            }
         });
         var data=await resp.json();
         setPic(data.pic);
         localStorage.setItem("pic",data.pic);
         } else {
         window.location.href="/";
         }

   }

   
   
   async function picupdate() {
      
      // if (flag==1) {
         // alert(newpic)
         var fd=new FormData();

         fd.append("pic",newpic);
         fd.append("enrollid",localStorage.getItem("enrollid2"));
         var resp=await axios.post("https://safalata.net/safalatalara/api/picupdate",fd);
         var data=resp.data;
         setMsg(data.msg);
         setMsgcode(data.msgcode);
         setMsgcolor(data.msgcolor);

         if (data.msgcode==1) {
             setIsbtn(true);

             window.scrollTo({
                 top: 0,
                 behavior: "smooth"
             });
             getToken();
         }
      // }
   }

   useEffect(()=>{
      getToken();
      getNostorage();
   },[])

return(
<>
<div id="content">
   <div className="p-4 contentWrapper">
      <div className="row">
         <div className="col-lg-7 dasboard-wrapper">
            <h4 className="mb-3 font-weight-bold text-success">Profile Update Picture</h4>
         </div>
      </div>
      <div className="row mb-4">
         <div className="col-sm-12">
            <div className="card">
               <div className="card-body">
                  {/* <form className="needs-validation"> */}
                     <h6 className="card-title  text-defult font-weight-bold">Profile Update</h6>
                     <div className="row">
                        <div className="col-md-12">
                           <img src={"https://safalata.net/safalatalara/uploadpic/"+pic} alt="" className="profile_img rounded border border-success" style={{width: '200px'}} />
                           <div className="form-group" style={{marginTop: 50}}>
                              <input type="file" className="form-control-file" id="exampleFormControlFile1" onChange={(ev)=>{
                                 setNewpic(ev.target.files[0]);
                                 if (ev.target.files[0].name!=null) {
                                    setIsbtn(false);
                                 } else {
                                    setIsbtn(true);
                                 }
                              }} accept=".jpg,.jpeg"/>
                           </div>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-md-12 mt-2">
                           <input type="button" className="mt-1 btn btn-primary" value="Update Profile Picture" disabled={isbtn} onClick={picupdate} />  
                           {/* disabled={isbtn}  */}
                        </div>
                     </div>
                  {/* </form> */}
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</>
);
}
export default Updateprofilepiccontent;
