import react, {useEffect, useState} from "react";
import {BrowserRouter, Routes,Route, Link} from 'react-router-dom';

import $ from 'jquery';

import LoadingBar from 'react-top-loading-bar';

import axios from "axios";

import {useNavigate} from "react-router-dom";

function Examheader() {

  let navigate = useNavigate();

  const [progress, setProgress] = useState(200);

  async function getCheckToken() {

    var fd=new FormData();
    fd.append("enrollid",localStorage.getItem("enrollid"));
    var resp=await axios.post("https://safalata.net/safalatalara/api/checkusertoken",fd);
    var data=resp.data;
    if (data.usertoken!=localStorage.getItem('usertoken')) {
      localStorage.clear();
      let path = `/`; 
      navigate(path);
    }

    // console.log('db session : '+data.usertoken+' , session : '+localStorage.getItem('usertoken'))

  }

  useEffect(()=>{

    getCheckToken();

  }, []);

  return(
    <>
    {/* <div class="overlay"></div> */}

    <LoadingBar
        color='#ff4561'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />

   <nav className="xp-topnav navbar fixed-top navbar-expand navbar-dark">
  {/* <button type="button" id="sidebarCollapse" className="btn btn-navbar" onClick={()=>{
      $('#sidebar').toggleClass('active');
      //$('.overlay').toggleClass('active');

      
    }}> <i className="glyphicon glyphicon-align-left"></i></button> */}

            <img src="../images/logo.png" alt="" className="logo" />
            
            
            <ul className="navbar-nav ml-auto">
        
                <li className="nav-item dropdown">
                    {/* <a className="nav-link dropdown-toggle" id="userDropdown" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">					 */}
                     <span className="avatar">
                        {/* <img src="../images/avatar.jpg" alt="user" className="avatar-img rounded-circle img-fluid" /> */}
                        <img src={"https://safalata.net/safalatalara/uploadpic/"+localStorage.getItem("pic")} alt="" className="avatar-img rounded-circle img-fluid" />
                    </span>
                   
                     {/* </a> */}
                    {/* <div className="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                        <a className="dropdown-item" href="my-profile"><i className="glyphicon glyphicon-user mr-2"></i>Profile Settings</a>
                        <a className="dropdown-item" href="change-password"><i className="glyphicon glyphicon-user mr-2"></i>Change Password</a>
                        <a className="dropdown-item" href="update-profile-pic"><i className="glyphicon glyphicon-user mr-2"></i>Update Profile Photo</a>                                               
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="" onClick={()=>{
                          // localStorage.removeItem("studid");
                          localStorage.removeItem("tokenID");
                          window.location.href="/";
                        }}><i className="glyphicon glyphicon-off mr-2"></i>Logout</Link>
                    </div> */}
                </li>
            </ul>
        </nav>
    
    </>

  );
}

export default Examheader;
