// import react from "react";

import Registercontent from "./../content/RegisterContent";


function Register() {
  return(
    <>
    <Registercontent />     
    </>

  );
}

export default Register;







