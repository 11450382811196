import react, { useRef, useEffect, useState } from "react";
import axios from 'axios';
function Myprofilecontent() {

   if (localStorage.getItem("tokenID")==null) {
      window.location.href="/login";
   }

   function getNostorage() {
      if (localStorage.getItem("livesetid")!=null) {
        localStorage.removeItem("livesetid");
      }
   }

   let [email,setEmail]=useState("");
   let [phone,setPhone]=useState("");
   let [city,setCity]=useState("");
   let [pin,setPin]=useState("");
   let [addr,setAddr]=useState("");
   let [dept,setDept]=useState("");
   let [design,setDesign]=useState("");

   let [batchname,setBatchname]=useState('');

   let [isbtn,setIsbtn]=useState(true);
   async function getToken() {
      var tokenval=localStorage.getItem("tokenID");
      if (tokenval!=null) {
         var resp=await fetch("https://safalata.net/safalatalara/api/gettoken", {
            headers: {
               "Content-Type":"application/json",
               "Authorization":"Bearer "+tokenval
            }
         });
         var data=await resp.json();

         setEmail(data.email);
         setPhone(data.phone);
         setCity(data.city);
         setPin(data.pin_code);
         setAddr(data.addr);
         setDept(data.department);
         setDesign(data.designation);

      } else {
      window.location.href="/";
      }

   }

   async function dataupdate() {
      var fd=new FormData();
      // fd.append("name",name);
      fd.append("email",email);
      fd.append("city",city);
      fd.append("pin",pin);
      fd.append("addr",addr);
      fd.append("dept",dept);
      fd.append("design",design);
      fd.append("enrollid",localStorage.getItem("enrollid2"));
      var resp=await axios.post("https://safalata.net/safalatalara/api/dataupdate",fd);
      var data=resp.data;

      // setIscheck(false);
      setIsbtn(true);
      getToken();
   }

   async function getBatchname() {
      var fd=new FormData();
      fd.append("batchid",localStorage.getItem("batchid"));
      var resp=await axios.post("https://safalata.net/safalatalara/api/getbatchname",fd);
      var data=resp.data;
      setBatchname(data);
  }

   useEffect(()=>{
      getBatchname();
      getToken();
      getNostorage();
   },[])

return(
<>
<div id="content">
   <div className="p-4 contentWrapper">
      <div className="row">
         <div className="col-lg-7 dasboard-wrapper">
            <h4 className="mb-3 font-weight-bold text-success">Profile Update</h4>
         </div>
      </div>
      <div className="row mb-4">
         <div className="col-sm-12">
            <div className="card">
               <div className="card-body">
                     <h6 className="card-title  text-defult font-weight-bold">Primary Information</h6>
                     <div className="row">
                        <div className="col-md-6">
                           <div className="position-relative form-group">
                              <label for="stud_name" className="">Name</label>
                              <input name="stud_name" defaultValue={localStorage.getItem("name")} id="stud_name" type="text" className="form-control" disabled="true" />
                           </div>
                        </div>
                        <div className="col-md-6">
                           <div className="position-relative form-group">
                              <label for="stud_email" className="">Email</label>
                              <input name="stud_email" id="stud_email" placeholder="Update Email" type="email" onChange={(ev)=>{
                                 if (ev.target.value.length>0) {
                                    setEmail(ev.target.value);
                                 }
                              }} defaultValue={email} className="form-control" />
                              <div className="updateemailerr"></div>
                           </div>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-md-6">
                           <div className="position-relative form-group">
                              <label for="stud_enroll" className="">My Enroll ID</label>
                              <input name="stud_enroll_id" id="stud_enroll_id" type="text" value={localStorage.getItem("enrollid2")} className="form-control" disabled="true" />
                           </div>
                        </div>
                        <div className="col-md-6">
                           <div className="position-relative form-group">
                              <label for="batch_name" className="">Inside Batch Name</label>
                              <input name="batch_name" id="batch_name" type="text" value={batchname} className="form-control" disabled="true" />
                           </div>
                        </div>
                     </div>
                     <h6 className="card-title  text-defult font-weight-bold">Communication</h6>
                     <div className="row">
                        <div className="col-md-6">
                           <div className="position-relative form-group">
                              <label for="stud_phone" className="">Phone</label>
                              <input name="stud_phone" id="stud_phone" placeholder="with a placeholder" type="number" value={phone} className="form-control" disabled="true" />
                           </div>
                        </div>
                        <div className="col-md-6">
                           <div className="position-relative form-group">
                              <label for="stud_city" className="">City</label>
                              <input name="stud_city" id="stud_city" type="text"  defaultValue={city} onChange={(ev)=>{
                                 if (ev.target.value.length>0) {
                                    setCity(ev.target.value);
                                 }
                              }} className="form-control" />
                              <div className="updatecityerr"></div>
                           </div>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-md-6">
                           <div className="position-relative form-group">
                              <label for="stud_pin_code" className="">Pin Code</label>
                              <input name="stud_pin_code" id="stud_pin_code" type="text" onChange={(ev)=>{
                                 if (ev.target.value.length==6) {
                                    setPin(ev.target.value);
                                 }
                              }} maxLength={6} defaultValue={pin} className="form-control" />
                              <div className="updatepincodeerr"></div>
                           </div>
                        </div>
                        <div className="col-md-6">
                           <div className="position-relative form-group">
                              <label for="stud_addr" className="">Address</label>
                              <textarea name="stud_addr" id="stud_addr" className="form-control" onChange={(ev)=>{
                                 if (ev.target.value.length>0) {
                                    setAddr(ev.target.value);
                                 }
                              }} defaultValue={addr} />
                              <div className="updateaddrerr"></div>
                           </div>
                        </div>
                     </div>
                     <h6 className="card-title  text-defult font-weight-bold">Career Information</h6>
                     <div className="row">
                        <div className="col-md-6">
                           <div className="position-relative form-group">
                              <label for="department" className="">Department</label>
                              <input name="department" id="department" type="text" onChange={(ev)=>{
                                 if (ev.target.value.length>0) {
                                    setDept(ev.target.value);
                                 }
                              }} defaultValue={dept} className="form-control" />
                              <div className="updatedepartmenterr"></div>
                           </div>
                        </div>
                        <div className="col-md-6">
                           <div className="position-relative form-group">
                              <label for="designation" className="">Designation</label>
                              <input name="designation" id="designation" type="text" onChange={(ev)=>{
                                 if (ev.target.value.length>0) {
                                    setDesign(ev.target.value);
                                 }
                              }} defaultValue={design} className="form-control" />
                              <div className="updatedesignationerr"></div>
                           </div>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-md-12">
                           <div className="position-relative form-check">
                              <label className="form-check-label text-center">
                              <input type="checkbox" className="form-check-input" id="checkmeout" onChange={(ev)=>{
                                 if (ev.target.checked) {
                                    setIsbtn(false);
                                 } else {
                                    setIsbtn(true);
                                 }
                              }} />
                              I do hereby declare that all the information given above are correct.</label>
                           </div>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-md-12 text-center mt-2">
                           <input type="button" className="mt-1 btn btn-primary" value="Update Profile" disabled={isbtn} onClick={dataupdate} />
                        </div>
                     </div>

               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</>
);
}
export default Myprofilecontent;