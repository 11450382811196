import react, { useEffect, useState } from "react";
import axios from 'axios';
import validator from 'validator';
import {Link} from 'react-router-dom';

import {useNavigate} from "react-router-dom";

function Login() {

  let navigate = useNavigate();

  // const baseurl="https://www.safalata.net/safalatalara";

  if (localStorage.getItem("tokenID")!=null) {
    // window.location.href="/dashboard";
    let path = `/dashboard`; 
    navigate(path);
  }

  // INPUT FIELD
  // let [loginid,setLoginid]=useState("");
  let [phone,setPhone]=useState("");
  let [password,setPassword]=useState("");
  // INPUT FIELD

  let [isbtn,setIsbtn]=useState(true);

  // VALIDATION
  // let errloginid="Valid Email ID Required";
  let errphone="Registered Phone Number Required";
  let errpassword="Password Required";

  // let [iserrloginid,setIsrrloginid]=useState(false);
  let [iserrphone,setIsrrphone]=useState(false);
  let [iserrpassword,setIsrrpassword]=useState(false);
  // VALIDATION

  // MSG
  let [alert,setAlert]=useState(false);
  let [msg,setMsg]=useState("");
  let [msgalert,setMsgalert]=useState("");
  // MSG

  let [exammode,setExammode]=useState("PACKAGE");

  async function studLogin() {
    var flag=1;
    // console.log(loginid);
    // console.log(password);
    // if (!validator.isEmail(loginid)) {
    //   flag=0;
    //   setIsrrloginid(true);
    // }
    if (phone.length<10) {
      flag=0;
      // setIsrrloginid(true);
      setIsrrphone(true);
    }
    if (password.length==0) {
      flag=0;
      setIsrrpassword(true);
    }
    if (flag==1) {
  
        var fd=new FormData();
        // fd.append("loginid",loginid);
        fd.append("phone",phone);
        fd.append("password",password);

        const response = await axios.post('https://safalata.net/safalatalara/api/studlogin', fd);
        const data = response.data;

        setAlert(true);
        setMsg(data.msg);
        setMsgalert(data.alert);

        if (data.msgcode==1) {

          localStorage.setItem('tokenID',data.stoken);

          var tokenval=localStorage.getItem("tokenID");
          if (tokenval!=null) {
            
            var resp=await fetch("https://safalata.net/safalatalara/api/gettoken", {
              headers: {
                "Content-Type":"application/json",
                "Authorization":"Bearer "+tokenval
              }
            });
            var data2=await resp.json();
            localStorage.setItem("enrollid2", data2.enroll_id);
            localStorage.setItem("enrollid", data2.enroll_id);
            localStorage.setItem("name", data2.name);
            localStorage.setItem("status", data2.status);
            localStorage.setItem("batchid",data2.batch_slno);
            localStorage.setItem("pic",data2.pic);
            localStorage.setItem("usertoken",data2.usertoken);

            localStorage.setItem("exammode",exammode);

            setIsbtn(true);
              let path = `/dashboard`; 
              navigate(path);
          }

        }
      
    }
    
  }


  return(
    <>
    <div className="loginBoxWrap">
        <div className="loginBox">
          <div className="logoRow"><img src="images/logo2.png" alt="" /></div>
        	{/* <div className="bg_white2" style={{background: '#030088'}}> */}
          <div className="bg_white2" style={{background: '#fff'}}>
          <h2 style={{fontSize: 'medium'}}>Shraddha Group of Education</h2>
          <div className="loginBoxContener p-4">
            <p className="text-center" style={{color: '#000',fontSize: 'large'}}>Student Login Portal</p>
              {alert?<div className={msgalert} style={{textAlign:'center'}}>{msg}</div>:''}
              <div className="form-group row">
                {/* <label for="inputEmail3" className="col-sm-12 col-form-label">Login ID</label>
                <div className="col-sm-12">
                  <input type="email" className="form-control" placeholder="Registered Email" onChange={(ev)=>{
                    setLoginid(ev.target.value);
                    if (validator.isEmail(loginid)) {
                      setIsrrloginid(false);
                    }
                    setAlert(false);
                  }}/>
                  {iserrloginid?<div className="text text-danger">{errloginid}</div>:''}
                </div> */}
                {/* <label for="inputPhone3" className="col-sm-12 col-form-label2">Registered Phone Number</label> */}
                <label for="inputPhone3" className="col-sm-12" style={{color: '#fff'}}>Registered Phone Number</label>
                <div className="col-sm-12">
                  <input type="tel" maxLength={10} className="form-control" placeholder="Registered Phone Number" onChange={(ev)=>{
                    setPhone(ev.target.value);
                    if (phone.length==10) {
                      setIsrrphone(false);
                    }
                    setAlert(false);
                  }}/>
                  {iserrphone?<div className="text text-danger">{errphone}</div>:''}
                </div>
              </div>
              <div className="form-group row">
                <label for="inputPassword3" className="col-sm-12 col-form-label" style={{color: '#fff'}}>Password</label>
                <div className="col-sm-12">
                  <input type="password" className="form-control" placeholder="Enter Password" onChange={(ev)=>{
                    setPassword(ev.target.value);
                    if (password.length>=1) {
                      setIsrrpassword(false);
                    }
                    setAlert(false);
                  }}/>
                  {iserrpassword?<div className="text text-danger">{errpassword}</div>:''}
                </div>
              </div>

              {/* <p className="card-title text-defult answerstxt disable-select" style={{marginLeft: 0, marginRight: 0, marginTop: 7, marginBottom: 7}}>
                <input type="radio" name="mode" value={"PACKAGE"} className="group1" style={{backgroundColor: 'rgba(252, 3, 98, 0.3)'}} checked onClick={(e)=>{
                  setExammode(e.target.value);
                }} />&nbsp;&nbsp;<span style={{color: '#333'}}>PACKAGE EXAM</span>    
              </p>
              <p className="card-title text-defult answerstxt disable-select" style={{marginLeft: 0, marginRight: 0, marginTop: 7, marginBottom: 7}}>
                <input type="radio" name="mode" value={"MOCK"} className="group1" style={{backgroundColor: 'rgba(252, 3, 98, 0.3)'}} onClick={(e)=>{
                  setExammode(e.target.value);
                }} />&nbsp;&nbsp;<span style={{color: '#333'}}>MOCK EXAM</span>    
              </p> */}
              

              {/* <div className="form-group row">
                <div className="col-sm-12">
                  <div className="form-check small">
                    <input className="form-check-input" type="checkbox" id="gridCheck1" onChange={(ev)=>{
                      if (ev.target.checked) {
                        setIsbtn(false);
                      } else {
                        setIsbtn(true)
                      }
                    }} />
                    <label className="form-check-label " for="gridCheck1"> Remember Me </label>
                  </div>
                </div>
              </div> */}

              <div className="form-group row text-center">
                <div className="col-sm-12">
                  <div className="form-check p-0">
                    <button className="btn btn-primary" onClick={studLogin}>Login</button>
                  </div>
                </div>
              </div>
              
              <div className="form-group row text-center">
                <div className="col-sm-12">
                  <div className="form-check p-0">
                    Forgot Password To <Link to="./forgot-password">CLICK HERE</Link>
                  </div>
                </div>
              </div>

              <div className="form-group row text-center">
                <div className="col-sm-12">
                  <div className="form-check p-0">
                    <button className="btn btn-danger" onClick={()=>{
                      let path = `/register`; 
                      navigate(path);
                    }}>New Registration</button>
                  </div>
                </div>
              </div>

              <div className="form-group row text-center">
                <div className="col-sm-12">
                  <div className="form-check p-0">
                    <button className="btn btn-secondary" onClick={()=>{
                      window.open('https://play.google.com/store/apps/details?id=in.safalata.mocktest','_blank')
                    }}>Download Safalata App</button>
                  </div>
                </div>
              </div>

          </div>
          </div>
         <div className="small mt-2 copyrighttext">&copy; Copyright 2022. All Rights Reserved.<br/>Powered By <a href="http://www.cheapwebguru.com" target="_blank">CHEAPWEBGURU.COM</a>.<br></br></div>
      </div>   
    </div>
    
    </>

  );
}

export default Login;







