import react from "react";
import Header from "./../include/Header";
import Sidebar from "./../include/Sidebar";
import ExamPracticeSetListContent from "./../content/ExamPracticeSetListContent";

function ExamPracticeSetList() {
    return(
        <>
        <Header />
        <div className="wrapper"> 
            <Sidebar />
            <ExamPracticeSetListContent />        
        </div>
        </>
    );
}

export default ExamPracticeSetList;