import react from "react";
import Header from "./../include/Header";
import Sidebar from "./../include/Sidebar";
import Packageplancontent from "./../content/Packageplancontent";

function Packageplan() {
    return(
        <>
        <Header />
        <div className="wrapper"> 
            <Sidebar />
            <Packageplancontent />        
        </div>
        </>
    );
}

export default Packageplan;