import react from "react";
import Header from "./../include/Header";
import Sidebar from "./../include/Sidebar";
import Thankyoucontent from "./../content/Thankyoucontent";

function Thankyou() {
    return(
        <>
        <Header />
        <div className="wrapper"> 
            <Sidebar />
            <Thankyoucontent />        
        </div>
        </>
    );
}

export default Thankyou;