import react, {useEffect, useState} from "react";
import {Link} from 'react-router-dom';

import $ from 'jquery';

import axios from "axios";

import {useNavigate} from "react-router-dom";

function Header() {

  let navigate = useNavigate();

  // async function logintokencheck() {
  //   if (!localStorage.getItem("usertoken")) {
  //       localStorage.removeItem("tokenID");
  //       localStorage.removeItem("enrollid2");
  //       let path = `/`; 
  //       navigate(path);
  //   }
  //     var fd=new FormData();
  //     fd.append("enrollid", localStorage.getItem("enrollid2"));
  //     fd.append("usertoken", localStorage.getItem("usertoken"));
  //     var resp=await axios.post("https://safalata.net/safalatalara/api/logintokencheck",fd);
  //     var data=resp.data;

  //     if (data.tokencount==0) {
  //       localStorage.removeItem("tokenID");
  //       localStorage.removeItem("enrollid2");
  //       localStorage.removeItem("usertoken");
  //       let path = `/`;
  //       navigate(path);
  //     }
  //     console.log(localStorage.getItem("usertoken"));

  // }

  async function getCheckToken() {

    var fd=new FormData();
    fd.append("enrollid",localStorage.getItem("enrollid"));
    var resp=await axios.post("https://safalata.net/safalatalara/api/checkusertoken",fd);
    var data=resp.data;
    if (data.usertoken!=localStorage.getItem('usertoken')) {
      localStorage.clear();
      let path = `/`; 
      navigate(path);

      console.log(data.usertoken+'=========>'+localStorage.getItem('usertoken'))
    }

    // console.log('db session : '+data.usertoken+' , session : '+localStorage.getItem('usertoken'))

  }

  let [pic,setPic]=useState("");

  async function getToken() {
    var tokenval=localStorage.getItem("tokenID");
      if (tokenval!=null) {
        var resp=await fetch("https://safalata.net/safalatalara/api/gettoken", {
            headers: {
               "Content-Type":"application/json",
               "Authorization":"Bearer "+tokenval
            }
        });
        var data=await resp.json();

        if (data.pic===null || data.pic==="") {
          setPic('user.png');
        } else {
          setPic(data.pic);
        }

      } else {
        let path = `/`; 
        navigate(path);
      }
  }

  useEffect(()=>{

      // logintokencheck();

      getCheckToken();

      getToken();

  }, []);

  return(
    <>
    {/* <div class="overlay"></div> */}

   <nav className="xp-topnav navbar fixed-top navbar-expand navbar-dark">
      
      <button type="button" id="sidebarCollapse" className="btn btn-navbar" onClick={()=>{
      $('#sidebar').toggleClass('active');
      // $('.overlay').toggleClass('active');
      
      }}> <i className="glyphicon glyphicon-align-left"></i></button>

            <Link className="navbar-brand" to="#"><img src="../../images/logo.png" alt="" className="logo" /></Link>
            
            
            <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                {pic!='user.png'?
                <img src={"https://safalata.net/safalatalara/uploadpic/"+pic} className="picUser" />
                :
                <img src="images/user.png" className="leftnavUser" />
                }
                </li>
                <li className="nav-item dropdown">
                   {/* <Link to="#" onClick={()=>{
                          localStorage.removeItem("tokenID");
                          localStorage.removeItem("enrollid2");
                          localStorage.removeItem("usertoken");
                          // window.location.href="/";
                          let path = `/`; 
                          navigate(path);
                        }} style={{color: '#fff'}}><i className="glyphicon glyphicon-off mr-2"></i>Logout</Link> */}

                    <button className="btn" onClick={()=>{
                          localStorage.removeItem("tokenID");
                          localStorage.removeItem("enrollid2");
                          localStorage.removeItem("usertoken");
                          localStorage.removeItem("exammode");
                          localStorage.clear();
                          // window.location.href="/";
                          let path = `/`; 
                          navigate(path);
                        }} style={{color: '#fff'}}><i className="glyphicon glyphicon-off mr-2"></i>Signout</button>

                    {/* <a className="nav-link dropdown-toggle" id="userDropdown" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">					 */}
                    
                    {/* <span className="avatar"> */}
                        {/* <img src="../../images/avatar.jpg" alt="user" className="avatar-img rounded-circle img-fluid" /> */}
                    {/* </span> */}
                   
                     {/* </a> */}
                    {/* <div className="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                        <a className="dropdown-item" href="my-profile"><i className="glyphicon glyphicon-user mr-2"></i>Profile Settings</a>
                        <a className="dropdown-item" href="change-password"><i className="glyphicon glyphicon-user mr-2"></i>Change Password</a>
                        <a className="dropdown-item" href="update-profile-pic"><i className="glyphicon glyphicon-user mr-2"></i>Update Profile Photo</a>                                               
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="" onClick={()=>{

                          localStorage.removeItem("tokenID");
                          window.location.href="/";
                        }}><i className="glyphicon glyphicon-off mr-2"></i>Logout</Link>
                    </div> */}
                </li>
            </ul>
        </nav>
    
    </>

  );
}

export default Header;
