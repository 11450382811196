import react, { useRef, useEffect, useState } from "react";
import axios from 'axios';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import dateFormat from 'dateformat';

// import DateCountDown from 'react-date-countdown-timer';

// For Dashboard Loader
import DashboardLoader from '../include/DashboardLoader';
// For Dashboard Loader

// For Noticebaord Loader
import NoticeboardLoader from '../include/NoticeboardLoader';
// For Noticebaord Loader

import {useNavigate} from "react-router-dom";

import {decode as base64_decode, encode as base64_encode} from 'base-64';

// FOR RAZORPAY PAYMENT GATE INTEGRATION
import $ from 'jquery';
import useRazorpay, { RazorpayOptions } from "react-razorpay";
// FOR RAZORPAY PAYMENT GATE INTEGRATION

import React from 'react';

import Modal from 'react-modal';

import { useParams } from 'react-router-dom';

const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

  function Successstorycontent(props) {

    let navigate = useNavigate();
    function getchecklogin() {
        if (localStorage.getItem("tokenID")==null) {
            let path = `/login`; 
            navigate(path);
        }
    }


    

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
      };

      const txtred={
          color: '#c70000',
          fontWeight: '700',
          fontSize: 'medium'
      };

      const txtblue={
        color: '#1500b2',
        fontWeight: '700',
        fontSize: 'large'
      };

      const txtpayred={
        color: '#d92400',
        fontWeight: '500',
        fontSize: 'small'
      };

      const txtwhite={
        color: '#000',
        fontWeight: '700',
        fontSize: '1.2rem'
      };

      const txtwhite2={
        color: '#000',
        fontWeight: '700',
        fontSize: 'smaller'
      };

      // const baseurl="https://www.safalata.net/safalatalara";

      let [isbtn,setIsbtn]=useState(true);

      let [studname,setStudname]=useState('');
      let [enrollid,setEnrollid]=useState('');
      let [studstatus,setStudstatus]=useState('');
      let [batchname,setBatchname]=useState('');
      let [pic,setPic]=useState(null);

      //today date
      const now = new Date();
      const curdate=dateFormat(now, "yyyy-mm-dd");


      //LOADER
      let [loader,setLoader]=useState(false);
      //LOADER



      async function getToken() {
        var tokenval=localStorage.getItem("tokenID");
        if (tokenval!=null) {
        
        var resp=await fetch("https://safalata.net/safalatalara/api/gettoken", {
           headers: {
              "Content-Type":"application/json",
              "Authorization":"Bearer "+tokenval
           }
        });
        var data2=await resp.json();
        localStorage.setItem("enrollid2", data2.enroll_id);
        localStorage.setItem("name", data2.name);
        localStorage.setItem("status", data2.status);
        localStorage.setItem("batchid",data2.batch_slno);
        localStorage.setItem("pic",data2.pic);
        localStorage.setItem("usertoken",data2.usertoken);
        setIsbtn(true);
           // let path = `/dashboard`; 
           // navigate(path);
       
        }
      }
    
      let [noticename, setNoticename]=useState("");
      let [noticedatetime, setNoticedatetime]=useState("");
      let [fees, setFees]=useState("");

      async function getnoticeboard() {
          setLoader(true);
          var resp=await axios.get("https://safalata.net/safalatalara/api/getnoticeboard");
          var data=resp.data;
          setNoticename(data.noticename);
          setNoticedatetime(data.noticedatetime);
          setFees(data.fees);
          setLoader(false);
      }

      let [allnoticeboardlist, setAllnoticeboardlist]=useState([]);

      async function noticeboarddetails() {
          var resp=await axios.get("https://safalata.net/safalatalara/api/getnoticeboard_mobapp");
          var data=resp.data;
          setAllnoticeboardlist(data.noticeboard);
      }

    let [successstory, setSuccessstory]=useState([]);
    async function getSuccessStoryFunc() {
        var resp=await axios.get("https://www.safalata.in/safalatalara/api/successallcandidate");
        var data=resp.data;

        setSuccessstory(data);
        // console.log(data)
    }

    useEffect(()=>{
        getToken();

        // getReduxToken();
        // getReduxNoticeBoard();
        getSuccessStoryFunc();
        getnoticeboard();
        noticeboarddetails();
        getchecklogin();
    }, []);

      return(
        <>        
        
        <div id="content">
            <div className="p-4 contentWrapper">
                {/* <div className="row">
                    <div className="col-lg-12 dasboard-wrapper">
                    <h4 className="mb-3 font-weight-bold text-success">Dasboard</h4>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-sm-7">
                    {!loader?
                        <p className="dashboardtxt">
                          <span style={{fontSize: 'large', color: '#870017', textTransform: 'uppercase'}}>{studname}</span><br/>
                          Enrollment ID : <span style={txtblue}>{enrollid}</span><br/>
                          Student Status : {studstatus==1?<span className="text text-success">ACTIVE</span>:<span className="text text-danger">INACTIVE</span>}
                        </p>                        
                        
                    :<DashboardLoader/>}
                    </div>


                    <div className="col-sm-5">
                    {!loader?
                        <h6 className="card-title text-defult font-weight-bold">
                          <span style={{fontWeight:700, fontSize: 'large'}}>Notice Board</span><br/><br/>
                          <span style={{fontWeight:600, fontSize: 'medium'}} className="text text-danger">Notice : {noticename}. ( Date : {dateFormat(noticedatetime, "mmmm dd, yyyy HH:MM")} )</span><br/>
                        </h6>

                    :<NoticeboardLoader/>}



                    </div>

                    
                </div> */}


                

                
                <Container>
                
                <Grid container >
                    <Grid item xs={12} p={0} color="red">
                        <Typography variant="filled" component="h5" pt={2} pb={5} fontSize={'1.5rem'} fontWeight="700" severity="success"  align="center" >ALL SUCCEED CANDIDATE LIST</Typography>
                    </Grid>
                    
                        {successstory.map((e)=>
    
                        // <Typography sx={{ width: '100%' }} key={e.id}>
                        <Grid item sm={6} xs={12} pl={0} p={1} key={e.id}>
                        <Box sx={{borderRadius: 2, cursor: "pointer", p: 2}} variant="contained" className='hbtn btn-testlightbg gridwidth' align={'center'}>
                        <img src={"https://safalata.in/safalatalara/uploadpic/"+e.image} p={1} className="successUser" /> 
                        <Typography pl={1.8} sx={{ fontSize: '20px' }}><b>{e.name}</b><br/>
                        {e.designation}<br/>
                        {e.department}<br/>
                        {e.city!=null?
                          e.city                        
                        :
                        ''
                        }
                        </Typography>
                        </Box>
                        </Grid>
                    
                        // </Typography>
                        )}
    
                    </Grid>
                    
                </Container>
                
           

            </div>  
        </div>



       
        </>
    );
}

export default Successstorycontent;