import {Link} from 'react-router-dom';

import react, { useRef, useEffect, useState } from "react";
import axios from 'axios';

import dateFormat from 'dateformat';

// For Dashboard Loader
import DashboardLoader from '../include/DashboardLoader';
// For Dashboard Loader
// For Noticebaord Loader
import NoticeboardLoader from '../include/NoticeboardLoader';
// For Noticebaord Loader

function Liveexamresultcontent(props) {

    const txtblue={
      color: '#1500b2',
      fontWeight: '700',
      fontSize: 'medium'
    };

    const txtwhite={
      color: '#000',
      fontWeight: '700',
      fontSize: 'medium'
    };

    let [isbtn,setIsbtn]=useState(true);
    //localStorage.setItem("studid",1000);

    // if (localStorage.getItem("studtoken")==null) {
    //   window.location.href="/";
    // }

    let [studname,setStudname]=useState('');
    let [enrollid,setEnrollid]=useState('');
    let [studstatus,setStudstatus]=useState('');
    let [batchname,setBatchname]=useState('');


    //today date
    const now = new Date();
    const curdate=dateFormat(now, "yyyy-mm-dd");


    //LOADER
    let [loader,setLoader]=useState(false);
    //LOADER

    let [pic,setPic]=useState(null);

    async function getToken() {

      setLoader(true);

      var tokenval=localStorage.getItem("tokenID");
      if (tokenval!=null) {
        var resp=await fetch("https://safalata.net/safalatalara/api/gettoken", {
          headers: {
            "Content-Type":"application/json",
            "Authorization":"Bearer "+tokenval
          }
        });
        var data=await resp.json();
        setStudname(data.name);
        setEnrollid(data.enroll_id);
        setStudstatus(data.status);
        setPic(data.pic);

        if (data.name.length>1 && localStorage.getItem("enrollid2")==null) {
          localStorage.setItem("enrollid2",data.enroll_id);
        }

        var fd=new FormData();
        fd.append("batchid",data.batch_slno);
        var resp2=await axios.post("https://safalata.net/safalatalara/api/getbatchname",fd);
        var data2=resp2.data;
        setBatchname(data2);

        setLoader(false);

      } else {
        window.location.href="/";
      }

    }

    // const listcount=0;

    //LIVE EXAM SCORE LIST
    let [livescorearr,setLivescorearr]=useState([]);

    async function livescorelist() {
      let enrollid=localStorage.getItem("enrollid2");
      var fd=new FormData();
      fd.append("enrollid",enrollid);
      var resp=await axios.post("https://safalata.net/safalatalara/api/livescorelist",fd);
      var data=resp.data;
      setLivescorearr(data.livescore);

      // listcount=data.livescore_count;
    }


    function getNostorage() {
      if (localStorage.getItem("livesetid")!=null) {
        localStorage.removeItem("livesetid");
      }
    }

    let [noticename, setNoticename]=useState("");
    let [noticedatetime, setNoticedatetime]=useState("");

    async function getnoticeboard() {
        var resp=await axios.get("https://safalata.net/safalatalara/api/getnoticeboard");
        var data=resp.data;
        setNoticename(data.noticename);
        setNoticedatetime(data.noticedatetime);
    }

    useEffect(()=>{
      getToken();
      livescorelist();
      getNostorage();

      getnoticeboard();
      
    },[])

    return(
        <>        
        
        {/* {listcount} */}

        <div id="content">
            <div className="p-4 contentWrapper">
                <div className="row">
                    <div className="col-lg-12 dasboard-wrapper">
                    <h4 className="mb-3 font-weight-bold text-success">Dasboard</h4>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-sm-7">
                    {!loader?
                      <p className="dashboardtxt">
                      {/* {pic!=null?<img src={"https://safalata.net/safalatalara/uploadpic/"+pic} alt="" className="profile_img rounded border border-success" style={{float: 'left', width: '100px', marginRight: 10, borderRadius: '5px'}} />:''} */}
                        <span style={{fontSize: 'x-large', color: '#870017', textTransform: 'uppercase'}}>{localStorage.getItem("name")}</span><br/>
                        Batch Name : <span style={txtblue}>{batchname}</span><br/>
                        Enrollment ID : <span style={txtblue}>{localStorage.getItem("enrollid2")}</span><br/>
                        Student Status : {studstatus==1?<span className="text text-success">ACTIVE</span>:<span className="text text-danger">INACTIVE</span>}
                      </p>                          
                        // <p className="dashboardtxt">{studname}</p>
                        // <p className="dashboardtxt">Batch Name : {batchname}</p>
                        // <p className="dashboardtxt">Enrollment ID : {enrollid}</p>
                        // <p className="dashboardtxt">Student Status : {studstatus==1?'ACTIVE':'INACTIVE'}</p>
                    
                    :<DashboardLoader/>}
                    </div>
                    {/* <div className="col-sm-3">
                      
                    </div> */}
                    <div className="col-sm-5">
                    {!loader?
                        <h6 className="card-title text-defult font-weight-bold">
                          <span style={{fontWeight:700, fontSize: 'large'}}>Notice Board</span><br/><br/>
                          <span style={{fontWeight:600, fontSize: 'medium'}} className="text text-danger">Notice : {noticename}. ( Date : {dateFormat(noticedatetime, "mmmm dd, yyyy HH:MM")} )</span><br/>
                          {/* <span style={{fontWeight:700, fontSize: 'medium'}}>Date : {dateFormat(noticedatetime, "mmmm dd, yyyy HH:MM")}</span> */}
                        </h6>
                        // <p>Notice : # Next new batch (Batch-13) is commenced from 8th January 2022 at 12.30 p.m. # We commence new batch in two different sessions - January (1st week) and July (1st Week). # This is 12 months course.
                        //  Date : 20-08-2021</p>
                    :<NoticeboardLoader/>}
                    </div>
                </div>

                <div className="row mb-4">
                     <div className="col-sm-12">
                         <h6 className="card-title text-defult font-weight-bold" style={{fontSize: 'large'}}>Live Score List</h6>
                         <div className="bg_white border rounded table-responsive">            
                            <table className="table">
                                <thead className="thead-dark">
                                  <tr>
                                    <th>Live Exam</th>
                                    <th>Total Mark</th>
                                    <th>Correct</th>
                                    <th>Wrong</th>
                                    <th>Skip</th>
                                    <th>Exam Date</th>
                                    <th className='text-center'>Scored</th>
                                    <th className='text-center'>Action</th>
                                    {/* <th className="text-center">Review</th> */}

                                  </tr>
                                </thead>
                                <tbody>
                      

                                {livescorearr.map((e)=>
                                  <tr key={e.stud_live_set_id}>

                                  <td>{e.liveset_name}</td>
                                  <td>{e.total_mark}</td>
                                  <td>{e.studliveexamscore_correct}</td>
                                  <td>{e.studliveexamscore_wrong}</td>
                                  <td>{e.studliveexamscore_skip}</td>
                                  <td>{dateFormat(e.studliveexamscore_date, "dd/mm/yyyy")}</td>
                                  <th className='text-center'>{e.stud_liveset_score}</th>
                                  <th className='text-center'><Link to={"/livescoreview/"+e.score_unique_id+"/"+e.stud_live_set_id}><button className="btn btn-primary" style={{marginRight: '5px'}}>View</button></Link></th>
                                  {/* <td className="text-center">
                                    <Link to={"/liveexamreview/"+dateFormat(e.studliveexamscore_date, "yyyy-mm-dd")+"/"+e.stud_live_set_id} className="btn btn-success">Review</Link>
                                  </td> */}
                                  </tr>
                                  
                                  )}


                                </tbody>
                              </table>
                        </div>
                    </div>
                </div>
           

            </div>  
        </div>
       
        </>
    );
}

export default Liveexamresultcontent;