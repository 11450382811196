import react, { useRef, useEffect, useState } from "react";
import axios from 'axios';

import dateFormat from 'dateformat';

// import DateCountDown from 'react-date-countdown-timer';

// For Dashboard Loader
import DashboardLoader from '../include/DashboardLoader';
// For Dashboard Loader

// For Noticebaord Loader
import NoticeboardLoader from '../include/NoticeboardLoader';
// For Noticebaord Loader

import {useNavigate} from "react-router-dom";

import {decode as base64_decode, encode as base64_encode} from 'base-64';

// FOR RAZORPAY PAYMENT GATE INTEGRATION
import $ from 'jquery';
import useRazorpay, { RazorpayOptions } from "react-razorpay";
// FOR RAZORPAY PAYMENT GATE INTEGRATION

import React from 'react';

import Modal from 'react-modal';

import { useParams } from 'react-router-dom';

const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function Mockpracticesetlistcontent() {
    
    let navigate = useNavigate();
    const params=useParams();

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
      };

      const txtred={
          color: '#c70000',
          fontWeight: '700',
          fontSize: 'medium'
      };

      const txtblue={
        color: '#1500b2',
        fontWeight: '700',
        fontSize: 'medium'
      };

      const txtpayred={
        color: '#d92400',
        fontWeight: '500',
        fontSize: 'small'
      };

      const txtwhite={
        color: '#000',
        fontWeight: '700',
        fontSize: 'small'
      };

      const txtpkg={
        color: '#000',
        fontWeight: '700',
        fontSize: '1.3rem'
      };

    let [noticename, setNoticename]=useState("");
    let [noticedatetime, setNoticedatetime]=useState("");
    let [fees, setFees]=useState("");

    async function getnoticeboard() {
        setLoader(true);
        var resp=await axios.get("https://safalata.net/safalatalara/api/getnoticeboard");
        var data=resp.data;
        setNoticename(data.noticename);
        setNoticedatetime(data.noticedatetime);
        setFees(data.fees);
        setLoader(false);
    }

    let [name,setName]=useState("");
    let [enrollid,setEnrollid]=useState("");
    let [status,setStatus]=useState("");

    

    function getchecklogin() {
        if (localStorage.getItem("tokenID")==null) {
            let path = `/login`; 
            navigate(path);
        }
    }

    //UPCOMING PRACTICE EXAM LIST
    let [loader,setLoader]=useState(false);
    let [practicesetarr,setPracticesetarr]=useState([]);
    let [countexamallot,setCountexamallot]=useState("");

    //today date
    const now = new Date();
    const curdate=dateFormat(now, "yyyy-mm-dd");

    async function packagewisepracticesetlist() {
      setLoader(true);
      var fd=new FormData();
      fd.append("enrollid",localStorage.getItem("enrollid"));
      var resp=await axios.post("https://safalata.net/safalatalara/api/mockwisepracticesetlist",fd);
      var data=resp.data;
      setPracticesetarr(data.exam);
      setLoader(false);
    }

    // let [orderstatus, setOrderstatus]=useState("");
    // async function getstudlogincheck() {
    //     var fd=new FormData();
    //     fd.append("enrollid",localStorage.getItem("enrollid"));
    //     var resp=await axios.post("https://safalata.net/safalatalara/api/studlogincheck",fd);
    //     var data=resp.data;
    //     setOrderstatus(data);
    // }

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let openmsg="This Practice Set Will Soon....."; 

    useEffect(()=>{

        getchecklogin();

        packagewisepracticesetlist();

        // getstudlogincheck();

    }, []);

    return(
        <>

<div id="content">
            <div className="p-4 contentWrapper">
                <div className="row">
                    <div className="col-lg-12 dasboard-wrapper">
                    <h4 className="mb-3 font-weight-bold text-success">Dasboard</h4>
                    </div>
                </div>
                
                <div className="row mb-4">
                     <div className="col-sm-12">
                         <h6 className="card-title text-defult font-weight-bold" style={txtwhite}> </h6>
                         <div className="bg_white border rounded">            
                            <table className="table">
                                <thead className="thead-bg">
                                  <tr>
                                    
                                    <th colSpan={2} style={txtpkg}>Mock Practice Set List (FREE)</th>                                  

                                  </tr>
                                  
                                </thead>
                                {/* <thead className="thead-bg2">
                                  <tr>
                                    
                                    <th colSpan={2} style={txtpkg}>{expirydays!=-1000? "(Validity left "+expirydays+" days)" : "(Purchase this plan)"}</th>                                  
                              
                                  </tr>
                                </thead> */}
                                <thead className="thead-dark">
                                  <tr>
                                    
                                    <th>Package Name</th>
                                    <th>Go Package</th>

                              
                                  </tr>
                                </thead>
                                <tbody>
                      
                                
                                  {practicesetarr.map((e)=>
                                  <tr key={e.practiceset_slno}>

                                    <td style={txtwhite}>
                                      {e.practiceset_name}
                                    </td>
                                    <td>
                                      <button className="btn btn-success" onClick={()=>{

                                        localStorage.setItem("shuffle",e.practiceexamsetselection_shuffle);
                                        console.log(localStorage.getItem("shuffle"));
                                        localStorage.setItem("practicesetid",e.practiceset_slno);
                                        let path = `/startpracticeset`;
                                        navigate(path);

                                      }}>Entrance</button></td>

                                  </tr>
                                  )}

                                </tbody>
                              </table>
                        </div>
                    </div>
                </div>

                {/* :
                <div>
                <div className="row mb-4">
                     <div className="col-sm-12">
                         <h6 className="card-title text-defult font-weight-bold" style={txtwhite}><i classname="fa fa-seedling"></i> Upcoming Live Set Exam <button className="btn btn-default" onClick={()=>{
                           upcomingliveexam();
                         }}><i className="fas fa-sync"></i></button></h6>
                         <div className="bg_white border rounded">            
                            <table className="table">
                                <thead className="thead-dark">
                                  <tr>
                                    
                                    <th>Live Exam</th>
                                    <th>Exam Date</th>
                                  
                                    <th>Time</th>
                                    <th className="text-center">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                      
                                 
                                {orderstatus==1?
                                  upcominglivearr.map((e)=>
                                  <tr key={e.liveset_slno}>

                                  <td style={txtwhite}>{e.liveset_name}</td>
                                  <td style={txtwhite}>{dateFormat(e.liveexamsetselection_start_schedule, "dd-mm-yyyy")}</td>
                                  <td style={txtwhite}>{dateFormat(e.liveexamsetselection_start_schedule, "HH:MM TT")}-{dateFormat(e.liveexamsetselection_end_schedule, "HH:MM TT")}</td>
                                  <td className="text-center">
                                  {(new Date(dateFormat(e.liveexamsetselection_start_schedule, "mmmm dd, yyyy HH:MM:00")).getTime())-(new Date().getTime())<=0?
                                    e.existscorecount==0?
                                    <button className="btn btn-success" onClick={()=>{
                                      localStorage.setItem("livesetid",e.liveset_slno);
                                      let path = `/startliveexam/`;
                                      navigate(path);
                                    }}>Go Exam</button>
                                    
                                    :
                                    <button className="btn btn-danger" disabled='true' onClick={()=>{
                                      
                                    }}>Exam Done</button>
                                    
                                  :
                                    <button className="btn btn-info" disabled={isbtn} onClick={()=>{
                                      
                                    }}>Exam Soon</button>}
                                  
                                  </td>
                                  </tr>
                                  )
                                :
                                ''
                                }

                                </tbody>
                              </table>
                        </div>
                    </div>
                </div>


                <div className="row mb-4">
                     <div className="col-sm-12">
                         <h6 className="card-title text-defult font-weight-bold" style={txtwhite}><i classname="fa fa-seedling"></i> Upcoming Practice Set Exam Upcoming Live Set Exam <button className="btn btn-default" onClick={()=>{
                           upcomingpracticeexam();
                         }}><i className="fas fa-sync"></i></button></h6>
                         <div className="bg_white border rounded table-responsive">            
                            <table className="table table-striped table-bordered">
                                <thead className="thead-dark">
                                  <tr>
                                    <th>Practice Exam</th>
                                    <th>Exam Date</th>
                                    <th>Time</th>
                                    <th className="text-center">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                      
                                {orderstatus==1?
                                  upcomingpracticearr.map((e)=>
                                  <tr key={e.practiceset_slno}>

                                  <td style={txtwhite2}>{e.practiceset_name}</td>
                                  <td style={txtwhite}>{dateFormat(e.practiceexamsetselection_start_schedule, "dd-mm-yyyy")}</td>
                                  <td style={txtwhite}>{dateFormat(e.practiceexamsetselection_start_schedule, "HH:MM TT")}-{dateFormat(e.practiceexamsetselection_end_schedule, "HH:MM TT")}</td>
                                  <td className="text-center">

                                   {(new Date(dateFormat(e.practiceexamsetselection_start_schedule, "mmmm dd, yyyy HH:MM:00")).getTime())-(new Date().getTime())<=0?
                                    <button className="btn btn-success" onClick={()=>{
                                      
                                      let path = `/startpracticeexam/`+base64_encode(e.practiceset_slno);
                                      navigate(path);
                                    }}>Go Exam</button>
                                    :
                                    <button className="btn btn-info" disabled={isbtn} onClick={()=>{
                                      
                                    }}>Exam Soon</button>}
                                  
                                  </td>
                                  </tr>
                                  )
                                  :
                                  ''
                                  }
                                

                                </tbody>
                              </table>
                        </div>
                    </div>
                </div>
                </div> */}
                
           

            </div>  
        </div>



        </>
    );
}

export default Mockpracticesetlistcontent;
