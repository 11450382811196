import react, { useRef, useEffect, useState } from "react";
import axios from 'axios';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import SchoolIcon from '@mui/icons-material/School';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import dateFormat from 'dateformat';

// import DateCountDown from 'react-date-countdown-timer';

// For Dashboard Loader
import DashboardLoader from '../include/DashboardLoader';
// For Dashboard Loader

// For Noticebaord Loader
import NoticeboardLoader from '../include/NoticeboardLoader';
// For Noticebaord Loader

import {useNavigate} from "react-router-dom";

import {decode as base64_decode, encode as base64_encode} from 'base-64';

// FOR RAZORPAY PAYMENT GATE INTEGRATION
import $ from 'jquery';
import useRazorpay, { RazorpayOptions } from "react-razorpay";
// FOR RAZORPAY PAYMENT GATE INTEGRATION

import Modal from 'react-modal';

import PackageBoxLoader from './PackageBoxLoader';

const styles = {
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


  function Dashboardcontent(props) {

      const Razorpay = useRazorpay();
      let navigate = useNavigate();

      const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
      };

      const txtred={
          color: '#c70000',
          fontWeight: '700',
          fontSize: 'medium'
      };

      const txtblue={
        color: '#1500b2',
        fontWeight: '700',
        fontSize: 'large'
      };

      const txtpayred={
        color: '#d92400',
        fontWeight: '500',
        fontSize: 'small'
      };

      const txtwhite={
        color: '#000',
        fontWeight: '700',
        fontSize: '1.2rem'
      };

      const txtwhite2={
        color: '#000',
        fontWeight: '700',
        fontSize: 'smaller'
      };

      // const baseurl="https://www.safalata.net/safalatalara";

      let [isbtn,setIsbtn]=useState(true);

      let [studname,setStudname]=useState('');
      let [enrollid,setEnrollid]=useState('');
      let [studstatus,setStudstatus]=useState('');
      let [batchname,setBatchname]=useState('');
      let [pic,setPic]=useState(null);

      //today date
      const now = new Date();
      const curdate=dateFormat(now, "yyyy-mm-dd");


      //LOADER
      let [loader,setLoader]=useState(false);
      //LOADER


      // async function getToken() {

      //   setLoader(true);

      //   var tokenval=localStorage.getItem("tokenID");
      //   if (tokenval!=null) {
          
      //     var resp=await fetch("https://safalata.net/safalatalara/api/gettoken", {
      //       headers: {
      //         "Content-Type":"application/json",
      //         "Authorization":"Bearer "+tokenval
      //       }
      //     });
      //     var data=await resp.json();
      //     setStudname(data.name);
      //     setEnrollid(data.enroll_id);
      //     setStudstatus(data.status);
      //     setPic(data.pic);

      //     var fd=new FormData();
      //     fd.append("batchid",data.batch_slno);
      //     var resp2=await axios.post("https://safalata.net/safalatalara/api/getbatchname",fd);
      //     var data2=resp2.data;
      //     setBatchname(data2);

      //     setLoader(false);

      //   } else {
      //     window.location.href="/login";
      //   }

      // }

      async function getToken() {
        var tokenval=localStorage.getItem("tokenID");
        if (tokenval!=null) {
        
        var resp=await fetch("https://safalata.net/safalatalara/api/gettoken", {
           headers: {
              "Content-Type":"application/json",
              "Authorization":"Bearer "+tokenval
           }
        });
        var data2=await resp.json();
        localStorage.setItem("enrollid2", data2.enroll_id);
        localStorage.setItem("name", data2.name);
        localStorage.setItem("status", data2.status);
        localStorage.setItem("batchid",data2.batch_slno);
        localStorage.setItem("pic",data2.pic);
        localStorage.setItem("usertoken",data2.usertoken);
        setIsbtn(true);
           // let path = `/dashboard`; 
           // navigate(path);
       
        }
      }

      // if (localStorage.getItem("tokenID")==null) {
      //       let path = `/`; 
      //       navigate(path);
      // }

      async function getBatchname() {
          // console.log(localStorage.getItem("pic"));
          setLoader(true);
          // var fd=new FormData();
          // fd.append("batchid",localStorage.getItem("batchid"));
          // var resp2=await axios.post("https://safalata.net/safalatalara/api/getbatchname",fd);
          // var data2=resp2.data;
          // setBatchname(data2);
          setPic(localStorage.getItem("pic"));
          setStudname(localStorage.getItem("name"));
          setEnrollid(localStorage.getItem("enrollid2"));
          setStudstatus(localStorage.getItem("status"));
          setLoader(false);
      }

      //UPCOMING LIVE EXAM LIST
      // let [upcominglivearr,setUpcominglivearr]=useState([]);

      // async function upcomingliveexam() {
      //   // console.log(enrollid);
      //   // console.log(curdate);
      //   // let enrollid=localStorage.getItem("enrollid2");
      //   var fd=new FormData();
      //   fd.append("enrollid",localStorage.getItem("enrollid2"));
      //   fd.append("curdate",curdate);
      //   var resp=await axios.post("https://safalata.net/safalatalara/api/upcomingliveexam_newsession",fd);
      //   var data=resp.data;
      //   setUpcominglivearr(data);
      // }


      // //UPCOMING PRACTICE EXAM LIST
      // let [upcomingpracticearr,setUpcomingpracticearr]=useState([]);

      // async function upcomingpracticeexam() {
      //   var fd=new FormData();
      //   fd.append("enrollid",localStorage.getItem("enrollid2"));
      //   fd.append("curdate",curdate);
      //   var resp=await axios.post("https://safalata.net/safalatalara/api/upcomingpracticeexam_newsession",fd);
      //   var data=resp.data;
      //   setUpcomingpracticearr(data);
      // }

      // console.log(localStorage.getItem("enrollid2"));
      // console.log(curdate);


      function getNostorage() {
        if (localStorage.getItem("livesetid")!=null) {
          localStorage.removeItem("livesetid");
        }
      }

      let [noticename, setNoticename]=useState("");
      let [noticedatetime, setNoticedatetime]=useState("");
      let [fees, setFees]=useState("");

      async function getnoticeboard() {
          setLoader(true);
          var resp=await axios.get("https://safalata.net/safalatalara/api/getnoticeboard");
          var data=resp.data;
          setNoticename(data.noticename);
          setNoticedatetime(data.noticedatetime);
          setFees(data.fees);
          setLoader(false);
      }

      let [allnoticeboardlist, setAllnoticeboardlist]=useState([]);

      async function noticeboarddetails() {
          var resp=await axios.get("https://safalata.net/safalatalara/api/getnoticeboard_mobapp");
          var data=resp.data;
          setAllnoticeboardlist(data.noticeboard);
      }

      let [orderstatus, setOrderstatus]=useState("");
      async function getstudlogincheck() {
          var fd=new FormData();
          fd.append("enrollid",localStorage.getItem("enrollid2"));
          var resp=await axios.post("https://safalata.net/safalatalara/api/studlogincheck",fd);
          var data=resp.data;
          setOrderstatus(data);
      }

      let [loader2,setLoader2]=useState(false);
      let [btnpaytxt,setBtnpaytxt]=useState("");

      //PAYMENT GATEWAY RAZORPAY INTEGRATION
      // function pay_online(oid,st,name,enrollid) {
      //   // setBtnpaytxt("PLEASE WAIT...");
      //   var name=name;
      //   var amt=Number(st);
      //   // var eid=enrollid;
      //   // setLoader2(true);
        
      //   $.ajax({
      //         type:'post',
      //         url:'https://safalata.net/safalatalara/api/gopayment',
      //         data:"amt="+amt+"&name="+name,
      //         success:function(result){
      //             var options = {
      //                   "key": "rzp_live_QrhcwcL0TIPoRe", 
      //                   // "key": "rzp_test_3pFj1UUL1ja69Q",
      //                   "amount": amt*100, 
      //                   "currency": "INR",
      //                   "name": "Safalata - Shraddha Group of Education",
      //                   "description": "Exam Fees",
      //                   "image": "https://www.safalata.net/safalatalara/payimage/payimg.jpg",
      //                   "handler": function (response){
      //                     $.ajax({
      //                         type:'post',
      //                         url:'https://safalata.net/safalatalara/api/gopayment',
      //                         data:"payment_id="+response.razorpay_payment_id+"&oid="+oid+"&enrollid="+enrollid+"&amt="+amt,
      //                         success:function(result){
      //                           setBtnpaytxt("Please wait .. it will be redirected to thank you page.")
      //                           //    window.location.href="/";
      //                           // let path = `/order/`+oid;
      //                           // if (response==1) {
      //                             // setBtnpaytxt("PAY NOW");
      //                             let path = `/thankyou`;
      //                             navigate(path);
      //                           // } else {
      //                           //   // setBtnpaytxt("PAY NOW");
      //                           // }
                                
      //                         }
      //                     });
      //                   }
      //               };
      //               var rzp1 = new Razorpay(options);
      //               rzp1.open();
      //           }
      //   });
      //   // setLoader2(false);
        
      // }


  let [endpaymentdate,setEndpaymentdate]=useState("");

  let [btnpay,setBtnpay]=useState(true);

  async function getTimeInterval() {

      var fd=new FormData();
      fd.append("enrollid",localStorage.getItem("enrollid2"));
      var resp=await axios.post("https://safalata.net/safalatalara/api/endpaymentdate",fd);
      var data=resp.data;
      setEndpaymentdate(data.end_payment_date);
      // console.log('hi '+data.end_payment_date);
      
  }

      


      // TIME INTERVAL COUNTDOWN
      // const [timerday,setTimerday]=useState("00");
      // const [timerhour,setTimerhour]=useState("00");
      // const [timermin,setTimermin]=useState("00");
      // const [timersec,setTimersec]=useState("00");

      const [datetimeinterval,setDateTimeInterval]=useState("");

      // const [enddate,setEnddate]=useState("");

      // let x=0;

      let interval = useRef();

      const startTimer = (endeddate) => {
          const coundownDate = new Date(endeddate).getTime();

          interval=setInterval(()=>{
              const now = new Date().getTime();
              const distance = coundownDate-now;

              const days=Math.floor(distance / (1000*60*60*24));
              const hours=Math.floor((distance % (1000*60*60*24) / (1000*60*60)));
              const mins=Math.floor((distance % (1000*60*60)) / (1000*60));
              const secs=Math.floor((distance % (1000*60)) / 1000);

              if (distance < 0) {
                  //stop timer code
                  clearInterval(interval);
                  setDateTimeInterval("Schedule End");
                  // setIsradio(true);
                  // setIsskipbtn(true);
                  // autosubmitscore();
                  // setIsbtn(false);

                  // logintokencheck();




                  
                  // setBtnpay(false);

              } else {
                  // setDateTimeInterval(days+"d "+hours+"h "+mins+"m "+secs+"s");
                  setDateTimeInterval(days+" Days");
                  // setIsradio(false);
                  // setIsbtn(true);

                  // logintokencheck();

                  // console.log(days);

                  if (days<8) {
                    setBtnpay(false);
                  }

              }
          
          }, 1000)
      };
      // TIME INTERVAL COUNTDOWN

      const [open3, setOpen3] = useState(false);
      const handleOpen3 = () => setOpen3(true);
      const handleClose3 = () => setOpen3(false);

      let [exammodetxt,setExammodetxt]=useState("");

      let [exampackages,setExampackages]=useState([]);
      async function getExamPackages() {
          var resp=await axios.get("https://safalata.net/safalatalara/api/getexampackages");
          var data=resp.data;
          setExampackages(data.obj);

          if (localStorage.getItem("exammode")==null) {
              setExammodetxt("New system has updated now....");
              setTimeout(()=>{
                  handleOpen3();
                  localStorage.setItem("exammode","PACKAGE");
              },2000);
              
              // console.log(localStorage.getItem("exammode"));
          }
      }

      let [packageloader,setPackageloader]=useState(true);

      function getTimeLoader() {
          // setPackageloader(true);
          setTimeout(()=>{
              setPackageloader(false);
          },1000)
      }
      

      useEffect(()=>{
        // getToken();


        // console.log(localStorage.getItem("exammode"));
        
        getBatchname();
        // upcomingliveexam();
        // upcomingpracticeexam();
        getNostorage();

        getnoticeboard();

        getstudlogincheck();

        getToken();

        getTimeInterval();

        getExamPackages();

        getTimeLoader();

        noticeboarddetails();
        
      },[])

      return(
        <>        
        
        <div id="content">
            <div className="p-4 contentWrapper">

                <div className="row mb-1">

                    <div className="col-sm-7">
                    <h4 className="mb-3 font-weight-bold text-success">Dashboard</h4>
                    {!loader?
                        <p className="dashboardtxt">
                          <span style={{fontSize: 'large', color: '#870017', textTransform: 'uppercase'}}>{studname}</span><br/>
                          Enrollment ID : <span style={txtblue}>{enrollid}</span>
                         </p>                        
                        
                    :<DashboardLoader/>}
                    </div>

                    <div className="col-sm-5">
                    {!loader?
                        <h6 className="card-title text-defult font-weight-bold">
                          <span style={{fontWeight:700, fontSize: 'large'}}>Notice Board</span><br/>
                          {allnoticeboardlist.map((e)=>

                            <div style={{fontWeight:600, fontSize: 'medium'}} className="text text-danger">
                              Notice : {e.noticeboard_details}. ( Date : {dateFormat(e.noticeboard_date_time, "mmmm dd, yyyy HH:MM")} )
                            </div>
                          )}
                          
                        </h6>
                    :
                    <NoticeboardLoader/>
                    }

                    </div>

                    
                </div>

                  
                <Container>
                {/* <Grid container >
                      {packageloader==false?
                        <Grid container>
                            {exampackages.map((e)=>
                            <Grid item sm={6} xs={6} p={1} pb={1}>
                                <Box sx={{borderRadius: 2, cursor: "pointer"}} variant="contained" fullWidth className='hbtn btn-bluebg' align={'center'}  onClick={()=>{
                                let path = '/packages/'+e.package_id;
                                navigate(path);
                                }}>
                                    {e.package_name.length<=19?
                                    <Grid height={'190px'}>
                                        <Typography sx={{color: '#fff',fontSize: '2rem', fontWeight: 900, paddingTop: 2}}>{e.package_name}</Typography>
                                        <Box sx={{ marginTop: 1.5, borderRadius: 2, marginLeft: '10px', marginRight: '10px', background: '#ffffff'}}>
                                            <Typography sx={{color: '#3d2be0',fontSize: '1.2rem', fontWeight: 900}}>Hindi & English</Typography>
                                            
                                        </Box>
                                        <Box sx={{ marginTop: 0.2, marginLeft: '10px', marginRight: '10px'}}>
                                            <Typography><TouchAppIcon sx={{color: '#fff',fontSize: '2rem', fontWeight: 900}} /></Typography>
                                            
                                        </Box>
                                    </Grid>
                                    :
                                    <Grid height={'190px'}>
                                        <Typography sx={{color: '#fff', fontSize: '1.8rem', fontWeight: 900, paddingTop: 2.5}}>{e.package_name}</Typography>
                                        <Box sx={{ marginTop: 0.7, marginLeft: '10px', marginRight: '10px'}}>
                                            <Typography ><TouchAppIcon sx={{color: '#fff',fontSize: '2rem', fontWeight: 900}} /></Typography>
                                        </Box> 
                                    </Grid>   
                                            
                                    }
                                    <Box sx={{borderRadius: 2}} fullWidth pt={-3} className='hbtn btn-whitebg' align={'center'}>
                                    <Typography sx={{color: '#3d2be0',fontSize: '1.4rem', fontWeight: 900}}>(MCQ - {e.total_mcq})</Typography>
                                    </Box>
                                </Box>
                                
                            </Grid>
                            )}


                            <Grid item sm={6} xs={6} p={1}>
                              <Box sx={{borderRadius: 2, cursor: "pointer"}} variant="contained" fullWidth className='hbtn btn-testpinkbg' align={'center'}  onClick={()=>{
                              let path = `/mockpracticesetlist`;
                              navigate(path);
                              }}>    
                              <Grid height={'188px'}>
                                  <Typography sx={{color: '#fff',fontSize: '2rem', fontWeight: 900, paddingTop: 4}}>Mock Test</Typography>
                                  <Box sx={{ marginTop: 4, borderRadius: 2, marginLeft: '15px', marginRight: '15px', background: '#ffffff'}}><Typography sx={{color: '#b6048f',fontSize: '1.8rem', fontWeight: 900}}>FREE</Typography></Box>
                              </Grid>                
                              
                              </Box> 
                              
                            </Grid>

                            <Grid item sm={6} xs={6} p={1}>
                                <Box sx={{borderRadius: 2, cursor: "pointer"}} variant="contained" fullWidth className='hbtn btn-testbluebg' align={'center'}  onClick={()=>{
                                let path = `/successstory`;
                                navigate(path);
                                }}>    
                                <Grid height={'120px'}><SchoolIcon sx={{fontSize: '6rem', color: 'white', paddingTop: 1}} /></Grid>
                                <Typography sx={{color: '#fff',fontSize: '1.5rem', fontWeight: 900, paddingBottom: 1}}>SUCCESS<br/>STORY</Typography>
                                </Box>      
                            </Grid>
                            
                            
                        </Grid>

                        
                    :
                    <PackageBoxLoader />
                    }
                </Grid> */}

                <Grid container>
                {packageloader==false?
                <Grid container>                   

                    {exampackages.map((e)=>
                    <Grid item sm={3} xs={12} p={1} pb={1}>
                        <Box sx={{borderRadius: 2, cursor: "pointer"}} variant="contained" className='hbtn btn-bluebg gridwidth' align={'center'} onClick={()=>{
                            let path = '/packages/'+e.package_id;
                            navigate(path);
                        }}>
                            <Grid height={'190px'}>
                                <Typography sx={{color: '#fff',fontSize: '2rem', fontWeight: 900, paddingTop: 2}}>{e.package_name}</Typography>
                                <Box sx={{ marginTop: 1.5, borderRadius: 2, marginLeft: '10px', marginRight: '10px', background: '#ffffff'}}>
                                    <Typography sx={{color: '#3d2be0',fontSize: '1.2rem', fontWeight: 900}}>Hindi & English</Typography>
                                    
                                </Box>
                                <Box sx={{ marginTop: 0.2, marginLeft: '10px', marginRight: '10px'}}>
                                    <Typography><TouchAppIcon sx={{color: '#fff',fontSize: '2rem', fontWeight: 900}} /></Typography>
                                    
                                </Box>
                            </Grid>
                            <Box sx={{borderRadius: 2}} pt={-3} className='hbtn btn-whitebg gridwidth' align={'center'}>
                            <Typography sx={{color: '#3d2be0',fontSize: '1.4rem', fontWeight: 900}}>(MCQ - {e.total_mcq})</Typography>
                            </Box>
                        </Box>
                        
                    </Grid>
                    )}


                    <Grid item sm={6} xs={12} p={1} className="gridwidth">
                        <Box sx={{borderRadius: 2, cursor: "pointer"}} variant="contained" className='hbtn btn-testpinkbg gridwidth' align={'center'} onClick={()=>{
                            let path = `/mockpracticesetlist`;
                            navigate(path);
                        }}>    
                        <Grid height={'200px'}>
                            <Typography sx={{color: '#fff',fontSize: '2rem', fontWeight: 900, paddingTop: 4}}>Mock Test</Typography>
                            <Box sx={{ marginTop: 4, borderRadius: 2, marginLeft: '15px', marginRight: '15px', background: '#ffffff', width: '200px'}}><Typography sx={{color: '#b6048f',fontSize: '1.8rem', fontWeight: 900}}>FREE</Typography></Box>
                        </Grid>           
                        </Box> 
                    </Grid>

                    <Grid item sm={6} xs={12} p={1}>
                        <Box sx={{borderRadius: 2, cursor: "pointer"}} variant="contained" className='hbtn btn-testbluebg gridwidth' align={'center'}  onClick={()=>{
                        let path = `/successstory`;
                        navigate(path);
                        }}>    
                        <Grid height={'130px'}><SchoolIcon sx={{fontSize: '6rem', color: 'white', paddingTop: 1}} /></Grid>
                        <Typography sx={{color: '#fff',fontSize: '1.5rem', fontWeight: 900, paddingBottom: 1}}>SUCCESS<br/>STORY</Typography>
                        </Box>      
                    </Grid>

                    
                    
                </Grid>
                :
                <PackageBoxLoader />
                }
                </Grid>
                </Container>
                
                

            </div>  
        </div>


        <Modal
            // open={open3}
            // onClose={handleClose3}
            // aria-labelledby="modal-modal-title"
            // aria-describedby="modal-modal-description"
            // className='hbtn btn-bluenoticeboard'
            isOpen={open3}
            onAfterOpen={handleClose3}
            // onRequestClose={closeModal}
            // style={customStyles}
            contentLabel="Example Modal"
        >
            
            <div id="modal-modal-description" className="text-red mt-1 mb-2">
                {exammodetxt}
            </div>
            <div textAlign="center" className='btnRow'>
                <button className="btn-info" onClick={handleClose3}>Close</button>  
            </div>     
       
        </Modal>

       
        </>
    );
}

export default Dashboardcontent;