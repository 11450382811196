import react, { useRef, useEffect, useState } from "react";
import axios from 'axios';

import {Link} from 'react-router-dom';

import dateFormat from 'dateformat';

import { useParams } from 'react-router-dom';

import React from 'react';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Answer Loader
import AnswerLoader from '../include/AnswerLoader';
// For Answer Loader
// For Button Loader
// import ButtonLoader from '../include/ButtonLoader';
// For Button Loader
// For Question Loader
import QuestionLoader from '../include/QuestionLoader';
// For Question Loader

// For Import QA Loader
import {data} from "../include/Loader";

import "../loader.css";
// For Import QA Loader

import {useNavigate} from "react-router-dom";

function PracticeSetShuffleContent(props) {

  let navigate = useNavigate();

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgroup={
        margin: '10px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        //setIsOpen(false);
    }
    // FOr Modal

    const params=useParams();

    const limit=params.qsl-1;

    const prev=params.qsl-1;
    const next=prev+2;
    
    //LOADER
    let [loader,setLoader]=useState(false);
    //LOADER

    let [issavebtn,setIssavebtn]=useState(true);
    if (localStorage.getItem("practicesetid")==null) {
        // window.location.href="/practiceexamresult";
            let path = `/practiceexamresult`;
            navigate(path);
    }

    async function securestaypracticeexam() {

      if (localStorage.getItem("enrollid2")==null) {
            let path = `/practiceexamresult`;
            navigate(path);
      }

    }


    let [isradio,setIsradio]=useState(false);


    let [qid,setQid]=useState("");
    let [question,setQuestion]=useState("");
    let [noted,setNoted]=useState("");
    let [answerarr,setAnswerarr]=useState([]);
    let [ansslno,setAnsslno]=useState("");

    let [isalertnoted,setIsalertnoted]=useState(false);

    let [studansoption,setStudansoption]=useState("");
    let [querypagecount,setQuerypagecount]=useState(0);
    let [rowpagenoarr,setRowpagenoarr]=useState([]);
    let [querycountcheck,setQuerycountcheck]=useState("");

    let [isskipbtn,setIsskipbtn]=useState(true);
    let [isnextbtn,setIsnextbtn]=useState(true);

    let [nextbtndisplay,setNextbtndisplay]=useState("block");
    

    let [correct,setCorrect]=useState("");
    let [wrong,setWrong]=useState("");
    let [studansmark,setStudansmark]=useState("");

    //today date
    const now = new Date();
    const curdate=dateFormat(now, "yyyy-mm-dd");

    // let [loader,setLoader]=useState(false);

    //year-month [e.g 2022-February] added format in studpracticeexamscore
    const yearmonth=dateFormat(now, "yyyy-mmmm");
    async function practiceexamqa() {

          setLoader(true);
          var fd=new FormData();
          fd.append("practicesetid",localStorage.getItem("practicesetid"));
          fd.append("limit",limit);
          fd.append("enrollid",localStorage.getItem("enrollid"));
          fd.append("shuffle",localStorage.getItem("shuffle"));
          fd.append("curdate",curdate);
          ////////////////ADDED FOR PARTIAL SHUFFLEING
          ////////////////fd.append("qcount",localStorage.getItem('qcount'));
          
          // if (localStorage.getItem("shuffle")==0) {
          //   var resp=await axios.post("https://safalata.net/safalatalara/api/practicesetqa0_sec",fd);
          //   var data=resp.data;
          // } else if (localStorage.getItem("shuffle")==1) {
          //   var resp=await axios.post("https://safalata.net/safalatalara/api/practicesetqa1_sec",fd);
          //   var data=resp.data;
          // } else if (localStorage.getItem("shuffle")==2) {
          //   var resp=await axios.post("https://safalata.net/safalatalara/api/practicesetqa2_sec",fd);
          //   var data=resp.data;
          // }

          var resp=await axios.post("https://safalata.net/safalatalara/api/practicesetqa2_sec",fd);
          var data=resp.data;  
          
          setQid(data.qid);
          setQuestion(data.question);
          setNoted(data.noted);
          setAnswerarr(data.ans);
          setStudansoption(data.stud_ans_option);
          
          setRowpagenoarr(data.row_page_no);
          setQuerypagecount(data.query_page_count);
  
          setQuerycountcheck(data.querycountcheck);
  
          localStorage.setItem('qid',data.qid);
          localStorage.setItem('studansoption',data.stud_ans_option);
          localStorage.setItem('querycountcheck',data.querycountcheck);
  
          if (data.querycountcheck==1) {
            setIsskipbtn(true);
            setIsnextbtn(false);
          } else if (data.querycountcheck==0) {
            setIsskipbtn(false);
            setIsnextbtn(true);
          }
  
          setLoader(false);
  
      }


      
    // TIME INTERVAL COUNTDOWN
    // const [timerday,setTimerday]=useState("00");
    // const [timerhour,setTimerhour]=useState("00");
    // const [timermin,setTimermin]=useState("00");
    // const [timersec,setTimersec]=useState("00");

    const [datetimeinterval,setDateTimeInterval]=useState("");

    // const [enddate,setEnddate]=useState("");

    // let x=0;

    let interval = useRef();

    const startTimer = (endeddate) => {
        const coundownDate = new Date(endeddate).getTime();

        interval=setInterval(()=>{
            const now = new Date().getTime();
            const distance = coundownDate-now;

            // const days=Math.floor(distance / (1000*60*60*24));
            const hours=Math.floor((distance % (1000*60*60*24) / (1000*60*60)));
            const mins=Math.floor((distance % (1000*60*60)) / (1000*60));
            const secs=Math.floor((distance % (1000*60)) / 1000);

            if (distance < 0) {
                //stop timer code
                clearInterval(interval);
                setDateTimeInterval("Schedule End");
                setIsradio(true);
                setIsskipbtn(true);
                // setIsbtn(false);

                // logintokencheck();

            } else {
                setDateTimeInterval(hours+"h "+mins+"m "+secs+"s");
                setIsradio(false);
                // setIsbtn(true);

                // logintokencheck();

            }
        
        }, 1)
    };
    // TIME INTERVAL COUNTDOWN


      async function savestudpracticeexamsheet() {
        // logintokencheck();
        setIssavebtn(true);
        setIsskipbtn(true);
        var fd=new FormData();
        fd.append("practicesetid",localStorage.getItem("practicesetid"));
        fd.append("enrollid",localStorage.getItem("enrollid"));
        fd.append("qid",qid);
        fd.append("ansslno",ansslno);
        fd.append("querycountcheck",querycountcheck);
        fd.append("studansoption",studansoption);
        fd.append("pageno",params.qsl);
        fd.append("shuffle",localStorage.getItem("shuffle"));
        var resp=await axios.post("https://safalata.net/safalatalara/api/savestudpracticesetsheet_sec",fd);
        var data=resp.data;

        localStorage.setItem('querycountcheck',data.querycountcheck);
        localStorage.setItem('studansoption',data.studansoption);
        
        setIsnextbtn(false);
        setIsalertnoted(true);
        setIsradio(true);
        
        //practiceexamqa();

        setCorrect(data.correct);
        setWrong(data.wrong);
        setStudansmark(data.studansmark);

        if (localStorage.getItem("querypagecount")==0) {
            localStorage.setItem("querypagecount",1);
        }

    }



    async function skipstudpracticeexamsheet() {
      setIsskipbtn(true);
      var fd=new FormData();
      fd.append("practicesetid",localStorage.getItem("practicesetid"));
      fd.append("enrollid",localStorage.getItem("enrollid"));
      fd.append("qid",qid);
      fd.append("querycountcheck",querycountcheck);
      fd.append("pageno",params.qsl);
      var resp=await axios.post("https://safalata.net/safalatalara/api/skipstudpracticesetsheet_sec",fd);
      var data=resp.data;
      
      setIsnextbtn(false);

      //practiceexamqa();
      // setLoader(false);
        
      if (localStorage.getItem("querypagecount")==0) {
        localStorage.setItem("querypagecount",1);
      }
      

    }

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let [oneqst,setOneqst]=useState("");

    // let [circleloader,setCircleloader]=useState(false);

    const [loaderopen, setLoaderopen] = useState(false);
    const loaderhandleOpen = () => setLoaderopen(true);
    const loaderhandleClose = () => setLoaderopen(false);

    const loaderhandlemodalClose = () => setLoaderopen(false);


    function modalhandleOpen() {
        setOneqst("");
        handleOpen();
    }

    async function submitscore() {
        if (querypagecount>0) {
            handleClose();
            // setCircleloader(true);

            loaderhandleOpen();

            var fd=new FormData();
            fd.append("practicesetid",localStorage.getItem("practicesetid"));
            fd.append("enrollid",localStorage.getItem("enrollid"));
            fd.append("examtotalqst",localStorage.getItem("qcount"));
            fd.append("curdate",curdate);
            fd.append("yearmonth",yearmonth);
            var resp=await axios.post("https://safalata.net/safalatalara/api/submitscorestudpracticeset_sec",fd);
            var data=resp.data;

            setTimeout(()=>{
                //Remove List of Exam localStorage
                localStorage.removeItem("practicesetid");
                localStorage.removeItem("qcount");
                localStorage.removeItem("shuffle");
                localStorage.removeItem('qid');
                localStorage.removeItem('studansoption');
                localStorage.removeItem('querycountcheck');

                localStorage.removeItem("querypagecount");

                loaderhandlemodalClose();
                let path = `/practiceexamresult`; 
                navigate(path);

            },3000);
        } else {
            // openModal();
            alert("Please, Attend One Question Before Submit Score..");

            // setLoaderopen(true)

        }

    }


    async function getCheckToken() {

        var fd=new FormData();
        fd.append("enrollid",localStorage.getItem("enrollid"));
        var resp=await axios.post("https://safalata.net/safalatalara/api/checkusertoken",fd);
        var data=resp.data;
        if (data.usertoken!=localStorage.getItem('usertoken')) {
          localStorage.clear();
          let path = `/`; 
          navigate(path);
        }
        
    }


    useEffect(()=>{

      setIsnextbtn(true);

      securestaypracticeexam();

      setIsradio(false);

      practiceexamqa();
      setIssavebtn(true);
      setIsalertnoted(false);
      setStudansmark("");

      getCheckToken();
      
    },[params.qsl])

    return(
        <>
        
        <div id="content">
            <div className="contentWrapper">
                

                <div className="td5">
                     <div className="col-sm-112">
                        
                        <div className="bg_yellow_new border rounded">   

                          <p className="text-defult font-weight-bold text-center" style={{fontSize: 'medium', margin: 0}}>PRACTICE TEST EXAM</p>   

                          <p className="card-title text-defult font-weight-bold text-center" style={{fontSize: 'large', color: '#0006b5'}}>{localStorage.getItem("examname")}</p>
                          {startTimer(dateFormat(localStorage.getItem("exameddate"), "mmmm dd, yyyy HH:MM:00"))}                          
                          <p className="card-title text-defult font-weight-bold text-center" style={{fontSize: 'medium', color: '#e61010'}}>( {datetimeinterval} )</p>
                          

                          {querypagecount>0?<span className="skipicon" style={{fontSize: 'small'}}><i className="fa fa-square"></i>Skip &mdash; </span> :''}
                          {querypagecount>0?    
                                              
                            rowpagenoarr.map((r)=>
                            r.stud_ans_option==null?
                            <span style={{fontSize: 'small', fontWeight: '700'}} key={r.studpracticeexamsheet_pageno}>&nbsp;&nbsp;<Link to={"../practicesetshuffle/"+r.studpracticeexamsheet_pageno} style={{color: '#ed9a00'}}>{r.studpracticeexamsheet_pageno}</Link>&nbsp;&nbsp;</span>
                            :''
                            )
                          :''
                          }
                          {querypagecount<=localStorage.getItem('qcount') && querypagecount>0?
                          <span style={{fontSize: 'small', fontWeight: 700}}>&nbsp;&nbsp;<Link to={"../practicesetshuffle/"+querypagecount} style={{color: '#000794'}}>Last Attend-{querypagecount}</Link>&nbsp;&nbsp;</span>
                          :''}

                          {/* PAGE NUMBER */}

                          {/* QUESTION */}
                          
                          <hr/> 
                          
                          
                          {!loader?<p className="questiontxt disable-select">
                            Question {params.qsl} of {localStorage.getItem('qcount')} <br/>                     
                              QID - {qid} <br/>
                            Question - {question} 
                          </p>
                          :<QuestionLoader/>}
                          <hr/>

                          {!loader?
                          // {/* ANSWERS */}
                          answerarr.map((e)=>
                          
                            // querycountcheck == 1 && studansoption.length > 0?
                            localStorage.getItem('querycountcheck') == 1 && localStorage.getItem('studansoption').length > 0?
                              // e.answer_slno == studansoption?
                              e.answer_slno==localStorage.getItem('studansoption')?

                              <p className="card-title text-defult font-weight-bold answerstxt disable-select" style={{marginLeft: 0, marginRight: 0, marginTop: 7, marginBottom: 7}} key={e.answer_slno}>
                                <input type="radio" name="ans" value={e.answer_slno} className="group1" style={{backgroundColor: 'rgba(252, 3, 98, 0.3)'}} checked disabled />&nbsp;&nbsp;<span style={{color: '#000'}}>{e.answer.replace(/&apos;/g, "'")}</span>    
                              </p>
                              : 
                              <p className="card-title text-defult font-weight-bold answerstxt disable-select" style={{marginLeft: 0, marginRight: 0, marginTop: 7, marginBottom: 7}} key={e.answer_slno}>
                                <input type="radio" name="ans" value={e.answer_slno} className="group1" style={{backgroundColor: 'rgba(252, 3, 98, 0.3)'}} disabled />&nbsp;&nbsp;<span style={{color: '#000'}}>{e.answer.replace(/&apos;/g, "'")}</span>    
                              </p>
                            : 
                            <p className="card-title text-defult font-weight-bold answerstxt disable-select" style={{marginLeft: 0, marginRight: 0, marginTop: 7, marginBottom: 7}} key={e.answer_slno}>
                              <input type="radio" name="ans" value={e.answer_slno} className="group1" style={{backgroundColor: 'rgba(252, 3, 98, 0.3)'}} onChange={()=>{
                                setAnsslno(e.answer_slno);
                                setIssavebtn(false);
                                // setIsskipbtn(true);
                              }} disabled={isradio} />&nbsp;&nbsp;<span style={{color: '#000'}}>{e.answer.replace(/&apos;/g, "'")}</span>    
                            </p>
                            
                          
                          )
                          :<AnswerLoader/>}<br/>
                          {/* ANSWERS */}
                          
                          {/* PREV, SAVE, SKIP, NEXT BUTTON */}
                          {studansmark==1?
                          <p className="card-title resdiv">
                            <span className="correcttxt">Correct Answered : {correct.replace(/&apos;/g, "'")}</span>
                          </p>
                          : studansmark=='-0.25'?
                          <p className="card-title resdiv">
                            <span className="wrongtxt">Wrong Answered : {wrong.replace(/&apos;/g, "'")}</span><br/>
                            <span className="correcttxt">Correct Answer is : {correct.replace(/&apos;/g, "'")}</span>
                          </p>
                          : ''
                          }
                          
                          {/* PREV, SAVE, SKIP, NEXT BUTTON */}
                            {/* <p className="card-title text-defult font-weight-bold startexamh2txt" style={{marginLeft: 0, marginRight: 0, marginTop: 20, marginBottom: 20}}> */}
                            {/* {!loader? */}
                            <div className="text-right">
                              {params.qsl > 1?
                              <Link to={"/practicesetshuffle/"+prev} className="btn btn-info text-center displayblockbtn" style={{background: '#4f38ff', color: '#fff'}}>Prev</Link>
                              :''}
                              
                              
                              <input type="button" className="btn btn-success text-center displayblockbtn" style={{background: '#4f38ff', color: '#fff'}} value="Save" disabled={issavebtn} onClick={savestudpracticeexamsheet}/>

                              <input type="button" className="btn btn-danger text-center displayblockbtn" style={{background: '#4f38ff', color: '#fff'}} value="Skip" disabled={isskipbtn} onClick={skipstudpracticeexamsheet}/>

                              {/* {params.qsl < examtotalqst? */}
                              {/* {params.qsl < localStorage.getItem("qcount")? */}
                              {params.qsl < 100?
                                isnextbtn==true?
                                <input type="button" className="btn btn-info text-center displayblockbtn" value="Next" disabled={isnextbtn} style={{background: '#4f38ff', color: '#fff'}} />
                                :
                                <Link to={"/practicesetshuffle/"+next} className="btn btn-info text-center displayblocknextbtn">Next</Link>
                              :''
                              }
                            </div>
                            {/* : <ButtonLoader/> */}
                            {/* </p> */}
                          {/* PREV, SAVE, SKIP, NEXT BUTTON */}
                          {/* {studansmark==1?
                          <p className="card-title resdiv">
                            <span className="correcttxt">Correct Answered : {correct.replace(/&apos;/g, "'")}</span>
                          </p>
                          : studansmark=='-0.25'?
                          <p className="card-title resdiv">
                            <span className="wrongtxt">Wrong Answered : {wrong.replace(/&apos;/g, "'")}</span><br/>
                            <span className="correcttxt">Correct Answer is : {correct.replace(/&apos;/g, "'")}</span>
                          </p>
                          : ''
                          } */}

                          {isalertnoted && noted!=null?
                          
                          <p className="card-title notedtxt" style={{marginLeft: 0, marginRight: 0, marginTop: 20, marginBottom: 20}}><hr/>[ Note: {noted.replace(/&apos;/g, "'")} ]</p>
                          :''}

                          <div className="text-center"><button className="btn btn-danger scorebtn" onClick={submitscore}>PRACTICE SCORE SUBMIT</button></div>

                        </div>
                    </div>
                </div>
           

            </div>  
        </div>

        {/* For Modal */}
       

        <Modal
            isOpen={loaderopen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >

            <div className="main">
                <div className="loaderWrapper">
                    {data.map((loader, index) => (
                    <div key={loader.name + index} className="loaderBox">
                        <loader.Component {...loader.props} />
                    </div>
                    ))}

                </div>
            </div>                        

        </Modal>
        {/* FOr Modal */}
       
        </>
    );
}

export default PracticeSetShuffleContent;