import {Link} from 'react-router-dom';

import react, { useRef, useEffect, useState } from "react";
import axios from 'axios';

import dateFormat from 'dateformat';

// For Dashboard Loader
import DashboardLoader from '../include/DashboardLoader';
// For Dashboard Loader
// For Noticebaord Loader
import NoticeboardLoader from '../include/NoticeboardLoader';
// For Noticebaord Loader

function Mypaymenthistorycontent(props) {

    const txtblue={
      color: '#1500b2',
      fontWeight: '700',
      fontSize: 'medium'
    };

    const txtwhite={
      color: '#000',
      fontWeight: '700',
      fontSize: 'medium'
    };

    let [studname,setStudname]=useState('');
    let [enrollid,setEnrollid]=useState('');
    let [studstatus,setStudstatus]=useState('');
    let [batchname,setBatchname]=useState('');
    let [pic,setPic]=useState(null);

    //today date
    const now = new Date();
    const curdate=dateFormat(now, "yyyy-mm-dd");


    //LOADER
    let [loader,setLoader]=useState(false);
    //LOADER

    // async function getToken() {

    //   setLoader(true);

    //   var tokenval=localStorage.getItem("tokenID");
    //   if (tokenval!=null) {
    //     var resp=await fetch("https://safalata.net/safalatalara/api/gettoken", {
    //       headers: {
    //         "Content-Type":"application/json",
    //         "Authorization":"Bearer "+tokenval
    //       }
    //     });
    //     var data=await resp.json();
    //     setStudname(data.name);
    //     setEnrollid(data.enroll_id);
    //     setStudstatus(data.status);
    //     setPic(data.pic);
    //     if (data.name.length>1 && localStorage.getItem("enrollid2")==null) {
    //       localStorage.setItem("enrollid2",data.enroll_id);
    //     }

    //     var fd=new FormData();
    //     fd.append("batchid",data.batch_slno);
    //     var resp2=await axios.post("https://safalata.net/safalatalara/api/getbatchname",fd);
    //     var data2=resp2.data;
    //     setBatchname(data2);

    //     setLoader(false);

    //   } else {
    //     window.location.href="/";
    //   }

    // }

    // const listcount=0;

    //PAYMENT LIST
    let i=0;
    let j=0;

    let [paidlistarr,setPaidlistarr]=useState([]);
    let [unpaidlistarr,setUnpaidlistarr]=useState([]);
    


    function getNostorage() {
      if (localStorage.getItem("livesetid")!=null) {
        localStorage.removeItem("livesetid");
      }
    }


    async function getpaymenthistory() {
        // alert(localStorage.getItem("enrollid2"));
        var fd=new FormData();
        fd.append("enrollid",localStorage.getItem("enrollid"));
        var resp=await axios.post("https://safalata.net/safalatalara/api/mypaymentlist_mock",fd);
        var data=resp.data; 
        setPaidlistarr(data.obj);
        // setUnpaidlistarr(data.unpaidlist);
        // console.log()
    }

    let [noticename, setNoticename]=useState("");
    let [noticedatetime, setNoticedatetime]=useState("");

    async function getnoticeboard() {
        setLoader(true);
        var resp=await axios.get("https://safalata.net/safalatalara/api/getnoticeboard");
        var data=resp.data;
        setNoticename(data.noticename);
        setNoticedatetime(data.noticedatetime);
        setLoader(false);
    }

    async function getBatchname() {
        setLoader(true);
        var fd=new FormData();
        fd.append("batchid",localStorage.getItem("batchid"));
        var resp2=await axios.post("https://safalata.net/safalatalara/api/getbatchname",fd);
        var data2=resp2.data;
        setBatchname(data2);
        setPic(localStorage.getItem("pic"));
        setStudname(localStorage.getItem("name"));
        setEnrollid(localStorage.getItem("enrollid2"));
        setStudstatus(localStorage.getItem("status"));
        setLoader(false);
    }

    let [allnoticeboardlist, setAllnoticeboardlist]=useState([]);

    async function noticeboarddetails() {
        var resp=await axios.get("https://safalata.net/safalatalara/api/getnoticeboard_mobapp");
        var data=resp.data;
        setAllnoticeboardlist(data.noticeboard);
    }

    useEffect(()=>{
      // getToken();
      getBatchname();
      getpaymenthistory();
      getNostorage();

      getnoticeboard();

      noticeboarddetails();

    },[])

    return(
        <>        
        
        {/* {listcount} */}

        <div id="content">
            <div className="p-4 contentWrapper">
            <div className="row mb-1">

<div className="col-sm-7">
<h4 className="mb-3 font-weight-bold text-success">Dasboard</h4>
{!loader?
    <p className="dashboardtxt">
      {/* {localStorage.getItem("pic").length>1?
      <img src={"https://safalata.net/safalatalara/uploadpic/"+localStorage.getItem("pic")} alt="" className="profile_img rounded border border-success" style={{float: 'left', width: '100px', marginRight: 10, borderRadius: '5px'}} />:''} */}
      <span style={{fontSize: 'large', color: '#870017', textTransform: 'uppercase'}}>{studname}</span><br/>
      {/* Batch Name : <span style={txtblue}>{batchname}</span><br/> */}
      Enrollment ID : <span style={txtblue}>{enrollid}</span>
      {/* Student Status : {studstatus==1?<span className="text text-success">ACTIVE</span>:<span className="text text-danger">INACTIVE</span>} */}
    </p>                        
    // <p className="dashboardtxt">{studname}</p>
    // <p className="dashboardtxt">Batch Name : {batchname}</p>
    // <p className="dashboardtxt">Enrollment ID : {enrollid}</p>
    // <p className="dashboardtxt">Student Status : {studstatus==1?'ACTIVE':'INACTIVE'}</p>

:<DashboardLoader/>}
</div>



{/* <div className="col-sm-3">
  
</div> */}
<div className="col-sm-5">
{!loader?
    <h6 className="card-title text-defult font-weight-bold">
      <span style={{fontWeight:700, fontSize: 'large'}}>Notice Board</span><br/>
      {allnoticeboardlist.map((e)=>

        <div style={{fontWeight:600, fontSize: 'medium'}} className="text text-danger">
          Notice : {e.noticeboard_details}. ( Date : {dateFormat(e.noticeboard_date_time, "mmmm dd, yyyy HH:MM")} )
        </div>
      )}
      
      {/* <span style={{fontWeight:700, fontSize: 'medium'}}>Date : {dateFormat(noticedatetime, "mmmm dd, yyyy HH:MM")}</span> */}
    </h6>
    // <p>Notice : # Next new batch (Batch-13) is commenced from 8th January 2022 at 12.30 p.m. # We commence new batch in two different sessions - January (1st week) and July (1st Week). # This is 12 months course.
    //  Date : 20-08-2021</p>
:<NoticeboardLoader/>}





</div>


</div>

               <div className="row mb-4">
                        <div className="col-sm-12">
                           <h6 className="card-title text-defult font-weight-bold" style={{fontSize: 'large'}}>MY PAYMENT LIST</h6>
                           <div className="bg_white border rounded">  
                                     
                              <table className="table">
                                <thead style={{background:'#005e05',color:'#fff'}}>
                                  <tr>
                                    {/* <th className='text-center'>#SL</th> */}
                                    <th>Order ID</th>
                                    <th className='text-center'>Payment ID</th>
                                    {/* <th className='text-center'>Payment Month</th> */}
                                    <th className='text-center'>Payment Date</th>
                                    <th className='text-center'>End Date</th>
                                    <th className='text-center'>Amount</th>
                                    <th className='text-center'>Total Pkg</th>
                                    {/* <th className='text-center'>Payment Status</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                      
                                 {/* {paidlistarr.map((e,i)=> */}
                                {paidlistarr.map((e)=> 
                                  <tr key={e.payment_list_id}>
                                    <td className='text-center' style={{fontWeight:700}}>{e.order_id}</td>
                                    <td style={{fontWeight:700}}>{e.payment_id}</td>
                                    {/* <td className='text-center' style={{fontWeight:700}}>{e.payment_list}</td> */}
                                    <td className='text-center' style={{fontWeight:700}}>{dateFormat(e.payment_date, "dd-mm-yyyy")}</td>
                                    <td className='text-center' style={{fontWeight:700}}>{dateFormat(e.end_payment_date, "dd-mm-yyyy")}</td>
                                    <td className='text-center' style={{fontWeight:700}}>{e.payment_amount}</td>
                                    <td className='text-center' style={{fontWeight:700}}>{e.total_package}</td>
                                    {/* <td className='text-center' style={{fontWeight:700}}>
                                    {e.payment_list_status==1?
                                      <span className="text text-success text-center">Success</span>
                                    :
                                      <span className="text text-danger text-center">Failed</span>
                                    }
                                    </td> */}
                                  </tr>
                                 )}

                                </tbody>
                              </table>
                        </div>
                    </div>
               </div>
           

            </div>  
        </div>
       
        </>
    );
}

export default Mypaymenthistorycontent;