import {Link} from 'react-router-dom';
import useScreenType from "react-screentype-hook";

// import { useState } from 'react';

import axios from 'axios';
import validator from 'validator';

import React, { useEffect, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';

// For Modal
import Modal from 'react-modal';
// For Modal

import {useNavigate} from "react-router-dom";

// FOR RAZORPAY PAYMENT GATE INTEGRATION
import $ from 'jquery';
import useRazorpay, { RazorpayOptions } from "react-razorpay";
// FOR RAZORPAY PAYMENT GATE INTEGRATION

function Registercontent() {

    let navigate = useNavigate();

    const screenType = useScreenType();

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgrouptitle={
        margin: '0px',
        fontWeight: 700,
        backgroundColor: 'rgba(9,9,9,0.2)'
    };

    const formgroup={
        margin: '20px',
        fontWeight: 700
    };

    const formgroup2={
        marginTop: '10px',
        marginBottom: '20px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal

    const [progress, setProgress] = useState(0);

    // const baseurl="https://www.safalata.net/safalatalara";

    const mgtop={
        marginTop: '112px'
    };

    let [studname,setStudname]=useState("");
    let [enrollid,setEnrollid]=useState("");

    let [name,setName]=useState("");
    let [dob,setDob]=useState("");
    let [phone,setPhone]=useState("");
    let [email,setEmail]=useState("");
    let [city,setCity]=useState("");
    let [qualification,setQualification]=useState("");
    let [extra,setExtra]=useState("");
    let [password,setPassword]=useState("");
    let [password2,setPassword2]=useState("");

    let [studentmode,setStudentmode]=useState("");
    let [isstudentmode,setIsstudentmode]=useState(false);

    let [nameerr,setNameerr]=useState("");
    let [doberr,setDoberr]=useState("");
    let [phoneerr,setPhoneerr]=useState("");
    let [emailerr,setEmailerr]=useState("");
    let [cityerr,setCityerr]=useState("");
    let [qualificationerr,setQualificationerr]=useState("");
    let [extraerr,setExtraerr]=useState("");
    let [passworderr,setPassworderr]=useState("");

    let [img,setImg]=useState(null);

    let [isquali,setIsquali]=useState(false);
    let [isextra,setIsextra]=useState(false);

    let [isbtn,setIsbtn]=useState(true);
    let [ischeck,setIscheck]=useState(false);

    let [msg,setMsg]=useState("");
    let [msgcode,setMsgcode]=useState("");
    let [msgcolor,setMsgcolor]=useState("");

    let [msg2,setMsg2]=useState("");
    let [msg3,setMsg3]=useState("");
    let [msg4,setMsg4]=useState("");
    let [msg5,setMsg5]=useState("");

    let [mapassingyrlabel,setMapassingyrlabel]=useState("");
    let [mapassingyr,setMapassingyr]=useState("");
    let [ismapassing,setIsmapassing]=useState(true);

    let [extrapassingyrlabel,setExtrapassingyrlabel]=useState("");
    let [extrapassingyr,setExtrapassingyr]=useState("");
    let [isextrapassing,setIsextrapassing]=useState(true);

    let [captiontext,setCaptiontext]=useState("");

    let [instruction,setInstruction]=useState("");
    let [isinstruction,setIsinstruction]=useState(false);
    let [instructiontext,setInstructiontext]=useState("");
        
    let [loader,setLoader]=useState(false);

    let [divshow,setDivshow]=useState("block");

    async function formsubmit() {

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        // console.log(studentmode);

        var flag=1;

        if (name.length==0) {
            setNameerr("Required Name");
            flag=0;
        }
        if (dob.length==0) {
            setDoberr("Required Date of Birth");
            flag=0;
        }
        if (phone.length<10) {
            setPhoneerr("Required Valid Phone Number");
            flag=0;
        }
        if (!validator.isEmail(email)) {
            setEmailerr("Required Valid Email ID");
            flag=0;
        }
        if (city.length==0) {
            setCityerr("Required City");
            flag=0;
        }
        // if (isquali==false) {
        //     setQualificationerr("Required Qualification");
        //     flag=0;
        // }
        // if (studentmode.length=="") {
        //     setIsstudentmode(true);
        //     flag=0;
        // }
        // if (isextra==false) {
        //     setExtraerr("Required PG Course");
        //     flag=0;
        // }
        if (password.length>0 && password2.length>0) {
            if (password.length<6 || password2.length<6 && password!=password2) {
                setPassworderr("Required 6-Alfanumeric/Digits Password");
                flag=0;
            } else if (password.length>=6 && password2.length>=6 && password!=password2) {
                setPassworderr("Password does not match");
                flag=0;
            }
        } else {
                setPassworderr("Required 6-Alfanumeric/Digits Password");
                flag=0;
        }


        if (flag==1) {
            setProgress(progress + 200);        

            setLoader(true);
            
            setIsbtn(true);

            var fd=new FormData();
            fd.append("name",name);
            fd.append("dob",dob);
            fd.append("phone",phone);
            fd.append("email",email);
            fd.append("city",city);
            fd.append("password",password);
            fd.append("img",img);
            var resp=await axios.post("https://www.safalata.net/safalatalara/api/studregister",fd);
            var data=resp.data;

            setMsg(data.msg);
            setMsg2(data.msg2);
            setMsg3(data.msg3);
            setMsg4(data.msg4);
            setMsg5(data.msg5);

            setMsgcode(data.msgcode);
            setMsgcolor(data.msgcolor);

            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });

            if (data.msgcode==1) {
                setIsbtn(true);
                setIscheck(true);
                setStudname(data.studname);
                setEnrollid(data.enrollid);

                setDivshow("none");

                setName("");
                setDob("");
                setPhone("");
                setEmail("");
                setCity("");
                setPassword("");
                setPassword2("");
                setNameerr("");
                setDoberr("");
                setPhoneerr("");
                setEmailerr("");
                setCityerr("");
                setPassworderr("");

                
            }

            setLoader(false);
        }

    }
  
  return(
    <>
    <div className="loginBoxWrap">
        <div className="loginBox__">
          <div className="logoRow"><img src="images/logo2.png" alt="" /></div>
          <div className="bg_white2" style={{background: '#fff'}}>
          <h2 style={{fontSize: 'medium', textAlign: 'center'}}>Shraddha Group of Education</h2>
          <div className="loginBoxContener p-0">
            <p className="text-center" style={{color: '#000',fontSize: 'large'}}>Student Registration</p>
            {msgcode>0?

                <div className="col-md-12 form-group text-center" style={{marginBottom: 30, padding: 10}}>
                    {msgcode==1?
                    <div>
                    <div style={{fontSize: 'x-large', fontWeight: 700, marginBottom: '20px'}} className={msgcolor}>
                        <span style={{color: '#00ba10'}}>{msg}</span><br/><br/>
                        {msg2}<br/><br/>
                        {msg3}<br/>
                        {msg4}<br/>
                        {msg5}
                    </div>
                    </div>
                    :
                    <div style={{fontSize: 'x-large', fontWeight: 700, marginBottom: '20px'}} className={msgcolor}>{msg}</div>
                    }

                    <div style={{fontSize: 'x-large', fontWeight: 700}} className={msgcolor}>Please click here for student login<br/>  <br/> 
                    {msgcode==1?
                    <button className="btn btn-primary" onClick={()=>{
                        let path = `/`;
                        navigate(path);
                    }} style={{fontSize: 'large'}}>LOGIN NOW</button>
                    :''}
                    </div>
                </div>
                :''}
              <div className="row content" >
                    <div className="col-lg-4 pt-4"></div>
                    <div className="col-lg-8 pt-4"  style={{ marginLeft: screenType.isMobile? '5px' : '220px' , marginRight: '0px'}}>


                        {/* <div className="row" style={{marginBottom: 40, border: '1px solid #999', padding: 20, borderRadius: '5px'}}> */}
                            <div style={{fontSize: 'x-large'}}>Personal Information</div>
                            
                            {/* <div className="row"> */}
                                <div className="col-md-12 form-group">
                                    <label for="name" style={{fontSize: 'large', fontWeight: 700}}>Student Name <span style={{fontSize:'small', color:'red'}}>*</span></label>
                                    <input type="text" className="form-control" onChange={(ev)=>{
                                        setName(ev.target.value);
                                        if (ev.target.value.length>0) {
                                            setNameerr("");
                                        }
                                    }} />
                                    {name.length==0?<div className='text text-danger'>{nameerr}</div>:''}
                                </div>
                            {/* </div> */}
                            
                            <div className="col-md-12 form-group" style={{marginTop: 20}}>
                                <label for="name" style={{fontSize: 'large', fontWeight: 700}}>Date of Birth <span style={{fontSize:'small', color:'red'}}>*</span></label>
                                <input type="date" className="form-control form-control-lg" onChange={(ev)=>{
                                    setDob(ev.target.value);
                                    if (ev.target.value.length>0) {
                                        setDoberr("");
                                    }
                                }} />
                                {dob.length==0?<div className='text text-danger'>{doberr}</div>:''}
                            </div>
                            <div className="col-md-12 form-group" style={{marginTop: 20}}>
                                <label for="name" style={{fontSize: 'large', fontWeight: 700}}>Phone Number <span style={{fontSize:'small', color:'red'}}>*</span></label>
                                <input type="tel" maxLength={10} className="form-control form-control-lg" onChange={(ev)=>{
                                    setPhone(ev.target.value);
                                    if (ev.target.value.length==10) {
                                        setPhoneerr("");
                                    }
                                }} />
                                {phone.length<10?<div className='text text-danger'>{phoneerr}</div>:''}
                            </div>
                            <div className="col-md-12 form-group" style={{marginTop: 20}}>
                                <label for="name" style={{fontSize: 'large', fontWeight: 700}}>Email ID <span style={{fontSize:'small', color:'red'}}>*</span></label>
                                <input type="email" className="form-control form-control-lg" onChange={(ev)=>{
                                    setEmail(ev.target.value);
                                    if (validator.isEmail(email)) {
                                        setEmailerr("");
                                    }                                    
                                }} />
                                {!validator.isEmail(email)?<div className='text text-danger'>{emailerr}</div>:''}
                            </div>
                            <div className="col-md-12 form-group" style={{marginTop: 20}}>
                                <label for="name" style={{fontSize: 'large', fontWeight: 700}}>City <span style={{fontSize:'small', color:'red'}}>*</span></label>
                                <input type="city" className="form-control form-control-lg" onChange={(ev)=>{
                                    setCity(ev.target.value);
                                    if (ev.target.value.length>0) {
                                        setCityerr("");
                                    }
                                }} />
                                {city.length==0?<div className='text text-danger'>{cityerr}</div>:''}
                            </div>


                            <hr style={{marginTop: 20}} />
                            <h5 className="card-title" style={{fontSize: 'x-large', marginTop: 10}}>Create Password</h5>
                            <div className="col-md-12 form-group">
                                <label for="name" style={{fontSize: 'large', fontWeight: 700, marginBottom: 20}}>New Password <span style={{fontSize:'small', color:'red'}}>*</span></label>
                                    <input type="password" className="form-control form-control-lg" onChange={(ev)=>{
                                        setPassword(ev.target.value);
                                        if (ev.target.value.length>=6 && password2.length>=6) {
                                            setPassworderr("");
                                        }
                                    }} />
                            </div>
                            <div className="col-md-12 form-group">
                                <label for="name" style={{fontSize: 'large', fontWeight: 700, marginBottom: 20}}>Retype New Password <span style={{fontSize:'small', color:'red'}}>*</span></label>
                                    <input type="password" className="form-control form-control-lg" onChange={(ev)=>{
                                        setPassword2(ev.target.value);
                                        if (ev.target.value.length>=6 && password.length>=6) {
                                            setPassworderr("");
                                        }
                                    }} />
                                    <div className='text text-danger'>{passworderr}</div>
                            </div>

                            <div className="col-md-12 form-group" style={{marginTop: 20}}>
                                <label for="name" style={{fontSize: 'large', fontWeight: 700, marginBottom: 20}}>Upload Profile Pic (Only .jpg, jpeg image upload) <span style={{fontSize:'small', color:'red'}}>(optional)</span></label>
                                    <input type="file" className="form-control form-control-lg" onChange={(ev)=>{
                                        setImg(ev.target.files[0]);
                                    }} accept='.jpg,.jpeg' />
                            </div>

                            <div className="form-check" style={{marginTop: 20}}>
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={(ev)=>{
                                    if (ev.target.checked==true) {
                                        setIsbtn(false);
                                    } else {
                                        setIsbtn(true);
                                    }
                                }} disabled={ischeck} />
                                <label className="form-check-label" for="flexCheckDefault">
                                    I hereby declare that all the above information is correct and accurate. I solemnly declare that all the information furnished in this document is free of errors to the best of my knowledge and also I have read and agree to the terms of use.
                                </label>
                            </div>

                            <div className="col-md-12 form-group text-center" style={{marginTop: 40}}>
                                <input type="button" className="btn btn-success" value="REGISTER" disabled={isbtn} style={{fontSize: 'x-large'}} onClick={formsubmit} />
                            </div>
                            
                            

                        {/* </div> */}
                    </div>
                    {/* <div className="col-lg-2 pt-4"> </div> */}
                    {/* <div className="col-lg-1 pt-4 pt-lg-0"></div> */}
                </div>
              
              <div className="form-group row text-center">
                <div className="col-sm-12">
                  <div className="form-check p-0">
                    <button className="btn btn-danger" onClick={()=>{
                      let path = `/login`; 
                      navigate(path);
                    }}>Existing Login</button>
                  </div>
                </div>
              </div>


          </div>
          </div>
         {/* <div className="small mt-2 copyrighttext">&copy; Copyright 2022. All Rights Reserved.<br/>Powered By <a href="http://www.cheapwebguru.com" target="_blank">CHEAPWEBGURU.COM</a>.<br></br></div> */}
      </div>   
    </div>
    
    </>

  );
}

export default Registercontent;







