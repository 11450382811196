import react from "react";
import Header from "./../include/Header";
import Sidebar from "./../include/Sidebar";
import StartPracticeSetContent from "./../content/StartPracticeSetContent";

function StartPracticeSet() {
    return(
        <>
        <Header />
        <div className="wrapper">
            <Sidebar />
            <StartPracticeSetContent />
        </div>
        </>
    );
}

export default StartPracticeSet;