import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

export default function PackageBoxLoader() {
  return (

    <Grid container spacing={1}>
        <Grid item xs={6}>
          
            <Skeleton variant="rectangular" width={170} height={118} />
            <Box sx={{ pt: 0.5 }}>
              <Skeleton width="60%" />
            </Box>
        </Grid>
        <Grid item xs={6}>
            <Skeleton variant="rectangular" width={170} height={118} />
            <Box sx={{ pt: 0.5 }}>
              <Skeleton width="60%" />
            </Box>

        </Grid>
        <Grid item xs={6}>
            <Skeleton variant="rectangular" width={170} height={118} />
            <Box sx={{ pt: 0.5 }}>
              <Skeleton width="60%" />
            </Box>

        </Grid>
        <Grid item xs={6}>
            <Skeleton variant="rectangular" width={170} height={118} />
            <Box sx={{ pt: 0.5 }}>
              <Skeleton width="60%" />
            </Box>

        </Grid>
        <Grid item xs={6}>
            <Skeleton variant="rectangular" width={170} height={118} />
            <Box sx={{ pt: 0.5 }}>
              <Skeleton width="60%" />
            </Box>

        </Grid>
        <Grid item xs={6}>
            <Skeleton variant="rectangular" width={170} height={118} />
            <Box sx={{ pt: 0.5 }}>
              <Skeleton width="60%" />
            </Box>

        </Grid>
        
    </Grid>
    

  );
}
