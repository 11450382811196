import React, {useState} from 'react';
import dateFormat from 'dateformat';
import {BrowserRouter, Routes,Route, Link} from 'react-router-dom';

import LoadingBar from 'react-top-loading-bar';

import {useNavigate} from "react-router-dom";

import { useMediaQuery } from 'react-responsive';

import $ from 'jquery';


function Sidebar(){
  let navigate=useNavigate();

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const now = new Date().getTime();

  const [progress, setProgress] = useState(200);

  return (
    <>
      <LoadingBar
        color='#ff0000'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />

       <nav id="sidebar">
    <div className="sidebar-header">
      <p>Today is {dateFormat(now, "dddd, mmmm dd, yyyy")}</p>
    </div>
    <ul className="list-unstyled components">
    	<li className="active">
        <Link to="/dashboard"><i className="glyphicon glyphicon-dashboard"></i>Dashboard</Link></li>
      <li> <a href="#pagesSubmenu" data-toggle="collapse" aria-expanded="false"><i className="glyphicon glyphicon-list-alt"></i>Manage My Profile</a>
        <ul className="collapse list-unstyled" id="pagesSubmenu">
          <li><Link to="/my-profile"><i className="glyphicon glyphicon-chevron-right"></i>Profile Settings</Link></li>
          <li><Link to="/change-password"><i className="glyphicon glyphicon-chevron-right"></i>Change Password</Link></li>
          <li><Link to="/update-profile-pic"><i className="glyphicon glyphicon-chevron-right"></i>Update Profile Picture</Link></li>          
        </ul>
      </li>
      <li><a href="#PaymentHistory" data-toggle="collapse" aria-expanded="false"><i className="glyphicon glyphicon-camera"></i>Payment Section</a>
      	<ul className="collapse list-unstyled" id="PaymentHistory">
          <li><Link to="/my-payment-history"><i className="glyphicon glyphicon-chevron-right"></i>Payment History </Link></li>
        </ul>
      	
      </li>

      <li><a href="#Result" data-toggle="collapse" aria-expanded="false"><i className="glyphicon glyphicon-camera"></i>Section Result</a>
      	<ul className="collapse list-unstyled" id="Result">
          {/* <li><Link to="/liveexamresult"><i className="glyphicon glyphicon-chevron-right"></i>Live Exam Result</Link></li> */}
          <li><Link to="/monthwisescore"><i className="glyphicon glyphicon-chevron-right"></i>Month Wise Score Report</Link></li>
          <li><Link to="/practiceexamresult"><i className="glyphicon glyphicon-chevron-right"></i>Practice Exam Result</Link></li>
        </ul>
      	
      </li>

      </ul>

      <ul className="list-unstyled components" style={{marginLeft: '10px', marginTop: '40px'}}>

      <li className="active" role="button" onClick={()=>{
          if (isMobile || isTablet) {
            $('#sidebar').toggleClass('active')
          }

          let path = `/packageplan`; 
          navigate(path);

        }} style={{fontSize: '1.3rem'}}>
        <i className="glyphicon glyphicon-shopping-cart"></i>Make Payment
      </li>

      </ul>

      <ul className="list-unstyled components" style={{marginTop: '40px'}}>
      {/* <hr/> */}
      <li><a href="#Job" data-toggle="collapse" aria-expanded="false"><i className="glyphicon glyphicon-camera"></i>Job Section</a>
      	<ul className="collapse list-unstyled" id="Job">
          <li><Link to="#" onClick={()=>{
            window.open("https://www.freejobalert.com/government-jobs/","_target");
          }}><i className="glyphicon glyphicon-chevron-right"></i>FreeJobAlert - Government Jobs</Link></li>
          <li><Link to="#" onClick={()=>{
            window.open("https://sarkariresult.com/","_target");
          }}><i className="glyphicon glyphicon-chevron-right"></i>Sarkari Result - Government Jobs</Link></li>
          <li><Link to="#" onClick={()=>{
            window.open("https://www.freshersworld.com/jobs/category/govt-sector-job-vacancies?src=fwhm","_target");
          }}><i className="glyphicon glyphicon-chevron-right"></i>Freshers World - Government Jobs</Link></li>
        </ul>
      	
      </li>


     
    </ul>
  </nav>
    
    </>
  );

}

export default Sidebar;
