import react from "react";
import Header from "./../include/Header";
import Sidebar from "./../include/Sidebar";
import Packagescontent from "./../content/Packagescontent";

function Packages() {
    return(
        <>
        <Header />
        <div className="wrapper"> 
            <Sidebar />
            <Packagescontent />        
        </div>
        </>
    );
}

export default Packages;